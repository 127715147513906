import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Login from "./app/pages/login/page"
import ForgotPassword from "./app/pages/forgotPassword/page"
import Alert from "./app/pages/alerts/page"
import Home from "./app/pages/home/page"
import UserProfile from "./app/pages/userProfile/page"
import Loads from "./app/pages/loads/page"
import Logout from "./app/pages/logout/page"
import LateTrips from "./app/pages/trips/latetrips"
import ExceptionList from "./app/pages/exceptionList/page"
import ExceptionDetails from "./app/pages/exceptionDetails/page"
import ExceptionDetailsCard from "./app/pages/exceptionDetailsCard/page"
import * as amplitude from '@amplitude/analytics-browser';
import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IntercomMessenger from "./app/components/Intercom";
import UploadRateCon from "./app/components/UploadRateCon/UploadRateCon";
import RateConfirmationPage from "./app/components/ConfirmRateCon/confirmRateCon";

import AvailableTrucksList from "./app/pages/availableTrucksList/page";

amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, {
  defaultTracking: true,
});
/**
 * On first load, fetch the current version from the server and store it in state
 * On every render, compare the current version with the latest version stored in the state
 * If the current version is different from the latest version show a notification to the user.
 */


const App = () => {
  const location = useLocation();
  const [currentVersion, setCurrentVersion] = React.useState(null);
  const [refreshToastVisible, setRefreshToastVisible] = React.useState(false);

  useEffect(() => {
    amplitude.logEvent('Page Viewed', {
      'Page URL': location.pathname,
      'Page Title': document.title,
    });
  }, [location]);

  const fetchLatestVersion = async () => {
    try {
      const response = await fetch("version.txt", {
        cache: "no-store",
        timeout: 1000,
      });
      const version = await response.text();
      return version.trim();
    } catch (error) {
      console.error("Error fetching version:", error);
    }
  };

  useEffect(() => {
    fetchLatestVersion().then((version) => {
      if (version && !isNaN(parseInt(version))) {
        setCurrentVersion(version);
      }
    });
  }, []);

  useEffect(() => {

    fetchLatestVersion().then((version) => {
      if (version) {
        if (!isNaN(parseInt(version))) {
          if (currentVersion && currentVersion !== version) {
            if (refreshToastVisible) {
              return;
            }
            toast.info("A new version of the app is available. Click here to refresh.", {
              autoClose: false,
              timeout: 0,
              onOpen: () => {
                setRefreshToastVisible(true);
              },
              onClick: () => {
                window.location.reload();
              },
              onClose: () => {
                setRefreshToastVisible(false);
              },
              closeButton: false,
            });
          }
        }
      } else {
        if (!isNaN(parseInt(version))) {
          setCurrentVersion(version);
        }
      }
    });
  });

  return (
    <div className="bg-layout-bg">
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/alerts" element={<Alert />} />
          <Route path="/home" element={<Home />} />
          <Route path="/loads" element={<Loads />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/trips" element={<LateTrips />} />
          <Route path="/exceptionlist" element={<ExceptionList />} />
          <Route path="/exceptiondetails" element={<ExceptionDetails />} />
          <Route path="/exceptionDetailsCard" element={<ExceptionDetailsCard />} />
          <Route path="/availableTrucksList" element={<AvailableTrucksList />} />
          <Route path="/uploadRateCon" element={<UploadRateCon />} />
          <Route path="/confirmRateCon" element={<RateConfirmationPage />} />
        </Routes>
      </div>
      <ToastContainer />
      <IntercomMessenger />
    </div>
  )
};

export default App;