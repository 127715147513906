"use client";
import { useEffect, useState } from "react";
import HomeLayout from "../../components/Layout/homeLayout";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchAvailableTrucksData } from "../../redux/reducer/index";
import React from "react";
import { useLocation } from "react-router-dom";
import useAuthGuard from "../../hooks/useAuthGuard";
import { Loading } from "../../components/Loading";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorhandling";

// import { AvailableTruckListDataFromAPI } from "./availableTrucksDataFromAPI"
import { displayDate, displayDateWithOffset, displayTimeWithOffset, parseTs } from "../../utils/dateutils";
import { bucketDataAvailableTrucks } from "../../utils/availableTrucks";
import UpdateFilter from "../../components/BlockButton/UpdateFilter";
import ClearFilter from "../../components/BlockButton/ClearFilter";
import ExceptionStatusDropdown from "../../components/Dropdown/exceptionStatusDropdown";

const AvailableTrucksList: React.FC = () => {
  useAuthGuard();
  const dispatch = useAppDispatch();
  const { availableTrucks, loading } = useAppSelector((state) => state.availableTrucks);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bucket = queryParams.get("bucket");
  const [filter, setFilter] = useState<string[]>(bucket ? [bucket] : ["ALL"]);
  const [newfilter, setNewFilter] = useState<string[]>([bucket]);

  // Download data from an API and store it in the state
  useEffect(() => {
    dispatch(fetchAvailableTrucksData()).then((actionResult) => {
      if (actionResult.type === "availableTrucksData/availableTrucksData/rejected") {
        handleError(actionResult.payload, toast, actionResult.payload);
      }
    });
    //dispatch(notesData());
  }, [dispatch]);

  const getData = () => {
    const { UNASSIGNED, PAST, TODAY, TOMORROW } = bucketDataAvailableTrucks([...availableTrucks])
    const filteredData = [];
    if (filter.includes("ALL"))
      return [...availableTrucks];
    if (filter.includes("UNASSIGNED") && UNASSIGNED.length > 0)
      filteredData.push(...UNASSIGNED);
    if (filter.includes("PAST") && PAST.length > 0)
      filteredData.push(...PAST);
    if (filter.includes("TODAY") && TODAY.length > 0)
      filteredData.push(...TODAY);
    if (filter.includes("TOMORROW") && TOMORROW.length > 0)
      filteredData.push(...TOMORROW);
    return filteredData;
  }

  const renderTable = () => {
    return (
      <div className="flex flex-col overflow-x-auto">
        <table
          className="whitespace-nowrap overflow-x-auto 
          min-w-full text-sm text-left rtl:text-right text-white"
        >
          <thead className="text-xs capitalize rounded-lg bg-tableHead-bg mb-4">
            <tr className="shadow-md">
              <th colSpan={12} className="rounded-tl-lg rounded-tr-lg px-6 py-3">
                <div className="flex gap-8 items-center">
                  <input
                    type="text"
                    placeholder="Search by Trip #"
                    className="border border-gray-300 px-4 py-1 text-sm rounded-full w-60 placeholder:font-light"
                  />
                  <button className="bg-[#7A7CC0] text-white px-4 py-1 text-sm font-normal rounded-full min-w-24">
                    Search
                  </button>
                </div>
              </th>
            </tr>
            <tr className="shadow-md">
              <th scope="col" className="rounded-bl-lg px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Time
              </th>
              <th scope="col" className="px-6 py-3">
                Truck #
              </th>
              <th scope="col" className="px-6 py-3">
                Trip #
              </th>
              <th scope="col" className="px-6 py-3">
                Last Stop Name
              </th>
              <th scope="col" className="px-6 py-3">
                Customer
              </th>
              <th scope="col" className="px-6 py-3">
                Driver
              </th>
              <th scope="col" className="px-6 py-3">
                City
              </th>
              <th scope="col" className="px-6 py-3">
                State
              </th>
              <th scope="col" className="px-6 py-3">
                Driver Home
              </th>
              <th scope="col" className="px-6 py-3">
                Home Time Start
              </th>
              <th scope="col" className="px-6 py-3 rounded-br-lg">
                Home Time End
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              className="rounded-lg bg-tableData-bg border-b
              text-gray-900 font-semibold"
            >
              <td colSpan={8} className="px-6 py-3"></td>
            </tr>
            {getData()?.map((item, index) => (
              <React.Fragment key={index}>
                <tr
                  onClick={() => { console.log(item) }}
                  key={item.truck_id}
                  className="shadow-md bg-white
                  text-gray-900 font-semibold hover:cursor-pointer"
                >
                  <td className="px-6 py-3 rounded-tl-lg rounded-bl-lg">
                    {displayDateWithOffset(item?.last_stop?.datetime, item?.last_stop?.timezone_offset)}
                  </td>
                  <td className="px-6 py-3">{displayTimeWithOffset(item?.last_stop?.datetime, item?.last_stop?.timezone_offset)}</td>
                  <td className="px-6 py-3">{item?.truck_id}</td>
                  <td className="px-6 py-3">{item?.trip_number}</td>
                  <td className="px-6 py-3">{item?.last_stop?.name}</td>
                  <td className="px-6 py-3">{item?.last_stop?.customer_name}</td>
                  <td className="px-6 py-3">{item?.driver?.name}</td>
                  <td className="px-6 py-3">{item?.last_stop?.address?.city}</td>
                  <td className="px-6 py-3">{item?.last_stop?.address?.state}</td>
                  <td className="px-6 py-3">{'city' in (item?.driver?.address || {}) ? item?.driver?.address?.city + ', ' + item?.driver?.address?.state : null}</td>
                  <td className="px-6 py-3">{displayDate(item?.event_details?.start_date_time)}</td>
                  <td className="px-6 py-3">{displayDate(item?.event_details?.end_date_time)}</td>
                </tr>
                <tr>
                  <td colSpan={8} className="px-6 py-1"></td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  if (loading) {
    return (
      <HomeLayout>
        <Loading />
      </HomeLayout>
    );
  }

  return (
    <HomeLayout>
      <div className="sm:px-11 sm:mt-4">
        <div
          className="text-xl mb-2 text-titleColor font-semibold"
        >
          {'Available Trucks'}
        </div>
        <div className="mb-4 flex flex-row gap-10">
        </div>
        <div className="mb-4 flex flex-row gap-10">
        </div>
        <div className="flex flex-row justify-between align-middle my-6">
          <ExceptionStatusDropdown
            options={[{
              label: "Before Today",
              value: "PAST"
            }, {
              label: "Today",
              value: "TODAY"
            }, {
              label: "Tomorrow Onwards",
              value: "TOMORROW"
            }, {
              label: "All",
              value: "ALL"
            }]}
            multiselect={true}
            selectedOptions={[bucket]}
            onChange={(selected) => {
              setNewFilter(selected);
            }}
            key={"Time of Last Trip"}
            name={"Time of Last Trip"}
          />
          <div className="inline-flex gap-8">
            <div className="align-middle my-auto">
              <UpdateFilter onClick={() => {
                setFilter(newfilter);
              }} />
            </div>
            <div className="align-middle my-auto">
              <ClearFilter onClick={() => {
                setNewFilter(["ALL"]);
                setFilter(["ALL"]);
              }} />
            </div>
          </div>
        </div>
        <div id="default-tab-content">
          <div role="tabpanel">
            {renderTable()}
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default AvailableTrucksList;
