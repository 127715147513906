type LoadRequirementsCardProps = {
  requiredTemperature?: string;
  allowableRange?: string;
  reeferMode?: string;
  source?: string;
  commodityType?: string;
};
export default function LoadRequirementsCard(props: LoadRequirementsCardProps) {
  return (
    <div className="flex flex-col w-full">
      <div className="text-xl text-[#1C244A] font-semibold mb-4">Load Requirements</div>
      <div className="p-4 rounded bg-gray-50 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 text-sm">
          {props.requiredTemperature && <div>
            <span className="text-[#606479]">Required Temp:</span>
            <span className="text-[#1C244A]"> {props.requiredTemperature + "° F"}</span>
          </div>}

          {props.allowableRange && <div>
            <span className="text-[#606479]">Allowable Range:</span>
            <span className="text-[#1C244A]"> {props.allowableRange}</span>
          </div>}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 text-sm mt-4">
          {props.reeferMode && <div>
            <span className="text-[#606479]">Reefer Mode:</span>
            <span className="text-[#1C244A]"> {props.reeferMode == "Set" ? "Start/Stop": props.reeferMode}</span>
          </div>}

          {props.source && <div>
            <span className="text-[#606479]">Source:</span>{" "}
            <span
              className="text-blue-500 border-b border-blue-500 cursor-pointer"
              onClick={() => window.open(props.source, "_blank")}
            >
              {"Alvys Link"}
            </span>
          </div>}
        </div>

        {props.commodityType && <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 text-sm mt-4">
          <div>
            <span className="text-[#606479]">Commodity Type:</span>
            <span className="text-[#1C244A]"> {props.commodityType}</span>
          </div>
        </div>}
      </div>
    </div>
  );
}
