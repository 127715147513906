import { combineReducers } from "@reduxjs/toolkit";
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getToken, isTokenExpired, refreshUserToken, signOut } from "../../firebase/config";

export const resetStore = createAction("RESET_STORE");

export const handleErrorAction = async (error) => {
  if (error.action) {
    switch (error.action) {
      case 'refreshToken':
        await refreshUserToken();
        break;
      case 'forceLogout':
        await signOut();
        break;
      default:
        console.log("Error");
        console.error(error);
        break;
    }
  }
}

const createRandomNDigits = (n) => {
  return Math.floor(Math.random() * (10 ** n));
}

type RequestHeaders = {
  [key: string]: string;
};

const withAuthHeader = async (
  headers: RequestHeaders
): Promise<RequestHeaders> => {
  let token = await getToken();
  if (!token) {
    return headers;
  } else {
    const tokenExpired = await isTokenExpired();
    if (tokenExpired) {
      token = await refreshUserToken();
      return {
        ...headers,
        Authorization: "Bearer " + token,
      };
    } else {
      return {
        ...headers,
        Authorization: "Bearer " + token,
      };
    }
  }
};

// Reducers
const initialState = {
  data: [],
  completedData: [],
  completedDataTS: 0,
  dataTS: 0,
  notes: [],
  notesModified: false,
  loading: false,
  completedLoading: false,
  error: null,
  completedError: null,
  clickIndex: "",
  etaTab: 0,
  availableTrucks: [], // Added this line
};

export const addNote = async (trip_id, notes_text) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: await withAuthHeader({ "Content-Type": "application/json" }),
      body: JSON.stringify({ trip_id: trip_id, note: notes_text }),
    };
    const res = await fetch(
      process.env.REACT_APP_API_SERVER + "/notes/",
      requestOptions
    );
    const json = await res.json();
    if (res.status !== 200) {
      return {
        success: false,
        message: json?.detail || json?.error || "Error creating note",
      };
    }
    return {
      success: true,
      data: json,
    };
  } catch (ex) {
    console.error(ex);
    return {
      success: false,
      message: ex.message,
    };
  }
};

export const updateNote = async (note_id, notes_text) => {
  const requestOptions = {
    method: "PUT",
    headers: await withAuthHeader({ "Content-Type": "application/json" }),
    body: JSON.stringify({ note: notes_text }),
  };
  return fetch(
    process.env.REACT_APP_API_SERVER + "/notes/" + note_id,
    requestOptions
  );
};

export const deleteNote = async (note_id) => {
  if (!note_id) {
    return;
  }
  const requestOptions = {
    method: "DELETE",
    headers: await withAuthHeader({ "Content-Type": "application/json" }),
  };
  return fetch(
    process.env.REACT_APP_API_SERVER + "/notes/" + note_id,
    requestOptions
  );
};

export const updateFlag = async (trip_id, flag) => {
  const requestOptions = {
    method: "POST",
    headers: await withAuthHeader({ "Content-Type": "application/json" }),
    body: JSON.stringify({ trip_id: trip_id, flag: flag }),
  };
  return fetch(
    process.env.REACT_APP_API_SERVER + "/v1/exception-status-tracker/flag",
    requestOptions
  );
}

// Create an async thunk action
export const fetchData = createAsyncThunk(
  "data/fetchData",
  async (_, { rejectWithValue }) => {
    try {
      const headers = await withAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      if (headers.Authorization) {
        const response = await fetch(
          process.env.REACT_APP_API_SERVER + "/v1/aggregation/loads",
          {
            mode: "cors",
            method: "POST",
            headers,
            body: JSON.stringify({
              username: process.env.REACT_APP_ALYVS_USERNAME,
              password: process.env.REACT_APP_ALYVS_PASSWORD,
              token: process.env.REACT_APP_SAMSARA_KEY,
            }),
          }
        );
        if (!response.ok) {
          const data = await response.json();
          handleErrorAction(data);
          return rejectWithValue({
            action: data?.action,
            message: data?.message,
          });
        }
        let data = await response.json();
        if (typeof data === "string") {
          data = JSON.parse(data);
          if (data.error) {
            throw new Error(data.error);
          }
        }
        data = data.map((d, index) => {
          return {
            ...d,
            index,
          };
        });
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue({
        action: error.action,
        message: error.message,
      });
    }
  }
);

// Create an async thunk action
export const fetchCompletedData = createAsyncThunk(
  "data/fetchCompletedData",
  async (_, { rejectWithValue }) => {
    try {
      const headers = await withAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      if (headers.Authorization) {
        const response = await fetch(
          process.env.REACT_APP_API_SERVER + "/v1/aggregation/loads",
          {
            mode: "cors",
            method: "POST",
            headers,
            body: JSON.stringify({
              username: process.env.REACT_APP_ALYVS_USERNAME,
              password: process.env.REACT_APP_ALYVS_PASSWORD,
              token: process.env.REACT_APP_SAMSARA_KEY,
              trip_status: "DELIVERED"
            }),
          }
        );
        if (!response.ok) {
          const data = await response.json();
          handleErrorAction(data);
          return rejectWithValue({
            action: data?.action,
            message: data?.message,
          });
        }
        let data = await response.json();
        if (typeof data === "string") {
          data = JSON.parse(data);
          if (data.error) {
            throw new Error(data.error);
          }
        }
        data = data.map((d, index) => {
          return {
            ...d,
            index,
          };
        });
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue({
        action: error.action,
        message: error.message,
      });
    }
  }
);

export const notesData = createAsyncThunk(
  "notes/notes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_SERVER + "/notes/",
        {
          mode: "cors",
          method: "GET",
          headers: await withAuthHeader({
            Accept: "application/json",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAvailableTrucksData = createAsyncThunk(
  "availableTrucksData/availableTrucksData",
  async (_, { rejectWithValue }) => {
    try {
      const headers = await withAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      if (headers.Authorization) {
        const response = await fetch(
          process.env.REACT_APP_API_SERVER + "/v1/available-trucks/all",
          {
            mode: "cors",
            method: "GET",
            headers,
          }
        );
        if (!response.ok) {
          const data = await response.json();
          handleErrorAction(data);
          return rejectWithValue({
            action: data?.action,
            message: data?.message,
          });
        }
        let data = await response.json();
        if (typeof data === "string") {
          data = JSON.parse(data);
          if (data.error) {
            throw new Error(data.error);
          }
        }
        data = data.Data
        data = data.map((d, index) => {
          return {
            ...d,
            index,
          };
        });
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue({
        action: error.action,
        message: error.message,
      });
    }
  }
);

// Create a slice for the data with reducers to handle the actions
const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setClickIndex: (state, action) => {
      state.clickIndex = action.payload;
    },
    setEtaTab: (state, action) => {
      state.etaTab = action.payload;
    },
    addManualLoad: (state, action) => {
      console.log(state)
      console.log(action)
      let tripNum = createRandomNDigits(7);
      while (state.data.find((d) => d.trip_num === tripNum)) {
        tripNum = createRandomNDigits(7);
      }
      state.data = [...state.data, { ...action.payload, manual: true, trip_num: action.payload.trip_num }];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        const manualLoads = (state.data || []).filter((d) => d.manual);
        state.data = [...action.payload, ...manualLoads];
        state.dataTS = Date.now();
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCompletedData.pending, (state) => {
        state.completedLoading = true;
      })
      .addCase(fetchCompletedData.fulfilled, (state, action) => {
        state.completedLoading = false;
        state.completedData = action.payload;
        state.completedDataTS = Date.now();
      })
      .addCase(fetchCompletedData.rejected, (state, action) => {
        state.completedLoading = false;
        state.completedError = action.payload;
      });
  },
});

// Create a slice for the data with reducers to handle the actions
const notesSlice = createSlice({
  name: "notesData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(notesData.pending, (state) => {
        state.loading = true;
      })
      .addCase(notesData.fulfilled, (state, action) => {
        state.loading = false;
        state.notes = action.payload;
      })
      .addCase(notesData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const availableTrucksSlice = createSlice({
  name: "availableTrucksData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableTrucksData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAvailableTrucksData.fulfilled, (state, action) => {
        state.loading = false;
        state.availableTrucks = action.payload;
      })
      .addCase(fetchAvailableTrucksData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    }
    });

// Combine Reducers
const appReducer = combineReducers({
  data: dataSlice.reducer,
  notes: notesSlice.reducer,
  availableTrucks: availableTrucksSlice.reducer,
  // Add other reducers here if you have more
});

const rootReducer = (state, action) => {
  if (resetStore.match(action)) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const { setClickIndex, setEtaTab, addManualLoad } = dataSlice.actions;

export default rootReducer;
