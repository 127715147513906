import { useEffect } from "react";

import Card from "./Latetrips";

//import jsonData from "../../staticData/cardsData.json";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchData } from "../../redux/reducer/index";
import { getFilteredData } from "../../utils/triputils";
import { Loading } from "../Loading";
import { toast } from "react-toastify";
import { SunIcon } from "@heroicons/react/24/solid";
import { handleError } from "../../utils/errorhandling";
import { ExceptionType, TemperatureComplianceStatus } from "../../types";

const ReeferCard = () => {
  const { data, completedData, dataTS, loading } = useAppSelector((state) => state.data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data.length > 0 && (dataTS + (10 * 60 * 1000)) > Date.now()) return;
    dispatch(fetchData()).then((actionResult) => {
      if (actionResult.type === "data/fetchData/rejected") {
        handleError(actionResult.payload, toast, actionResult.payload);
      }
    });
  }, [dispatch, fetchData]);

  return loading ? (
    <Loading />
  ) : (
    <Card
      icon={SunIcon}
      title="Temperature Monitoring"
      tripData={{
        "Company Driver Trips": {
          Violations: getFilteredData(data, completedData, { tripType: "internal", tempStatus: [TemperatureComplianceStatus.InViolation], module: ExceptionType.TEMP_VIOLATION }).length,
          Compliant: getFilteredData(data, completedData, { tripType: "internal", tempStatus: [TemperatureComplianceStatus.Compliant], module: ExceptionType.TEMP_VIOLATION }).length,
          // Completed: getFilteredData(data, completedData, { tripType: "internal", completed: true, module: ExceptionType.TEMP_VIOLATION }).length,
        },
        "External Driver Trips": {
          Violations: getFilteredData(data, completedData, { tripType: "external", tempStatus: [TemperatureComplianceStatus.InViolation], module: ExceptionType.TEMP_VIOLATION }).length,
          Compliant: getFilteredData(data, completedData, { tripType: "external", tempStatus: [TemperatureComplianceStatus.Compliant], module: ExceptionType.TEMP_VIOLATION }).length,
          // Completed: getFilteredData(data, completedData, { tripType: "external", completed: true, module: ExceptionType.TEMP_VIOLATION }).length,
        },
      }}
      links={{
        "Company Driver Trips": {
          Violations: `/exceptionList?filters=${JSON.stringify({
            tripType: "internal",
            tempStatus: [TemperatureComplianceStatus.InViolation],
            module: "tempViolation",
          })}`,
          Compliant: `/exceptionList?filters=${JSON.stringify({
            tripType: "internal",
            tempStatus: [TemperatureComplianceStatus.Compliant],
            module: "tempViolation",
          })}`,
          // Completed: `/exceptionList?filters=${JSON.stringify({
          //   tripType: "internal",
          //   completed: true,
          //   module: "tempViolation",
          // })}`,
        },
        "External Driver Trips": {
          Violations: `/exceptionList?filters=${JSON.stringify({
            tripType: "external",
            tempStatus: [TemperatureComplianceStatus.InViolation],
            module: "tempViolation",
          })}`,
          Compliant: `/exceptionList?filters=${JSON.stringify({
            tripType: "external",
            tempStatus: [TemperatureComplianceStatus.Compliant],
            module: "tempViolation",
          })}`,
          // Completed: `/exceptionList?filters=${JSON.stringify({
          //   tripType: "external",
          //   completed: true,
          //   module: "tempViolation",
          // })}`,
        },
      }}
    />
  );
};

export default ReeferCard;
