import { useEffect, useState } from 'react';
  
interface FormDropDownWithIdInputProps {
    label: string;
    value: string;
    id: string;
    onChange: (value: any, id: any) => void;
    options?: Record<string, any>;
}

function findBestMatch(input, values) {
    const leven = (a, b) => {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;
    const matrix = Array(a.length + 1).fill().map(() => Array(b.length + 1).fill(0));
    for (let i = 0; i <= a.length; i++) matrix[i][0] = i;
    for (let j = 0; j <= b.length; j++) matrix[0][j] = j;
    for (let i = 1; i <= a.length; i++) {
        for (let j = 1; j <= b.length; j++) {
        matrix[i][j] = Math.min(
            matrix[i-1][j] + 1,
            matrix[i][j-1] + 1,
            matrix[i-1][j-1] + (a[i-1] !== b[j-1] ? 1 : 0)
        );
        }
    }
    return matrix[a.length][b.length];
    };
    
    const match = Object.keys(values).reduce((best, current) => {
    const distance = leven(input ? input.toLowerCase(): "", current ? current.toLowerCase() : "");
    return distance < best.distance ? {name: current, distance} : best;
    }, {name: '', distance: Infinity});

    return match.name;
}

const FormDropDownWithIdInput = ({ label, value, onChange, options = {} }: FormDropDownWithIdInputProps) => {
    const [inputValue, setInputValue] = useState(value);
    const [showDropdown, setShowDropdown] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
    const match = findBestMatch(value, options);

    useEffect(() => {
        console.log(match + " " + options[match])
        // Call onChange immediately when options are available
        if (Object.keys(options).length > 0) {
          const match = findBestMatch(value, options);
          onChange(match, options[match]);
        }
      }, [options]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setInputValue(val);
        setShowDropdown(true);
        
        const filtered = Object.keys(options).filter(key => 
            key.toLowerCase().includes(val.toLowerCase())
        );
        setFilteredOptions(filtered);
    };

    const handleSelect = (key: string) => {
        setInputValue(key);
        setShowDropdown(false);
        onChange(key, options[key]);
    };

    return (
        <div className="relative">
            <label className="block text-sm font-medium text-gray-700 mb-1">
                {label}
            </label>
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {showDropdown && filteredOptions.length > 0 && (
                <ul className="absolute w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto z-10">
                    {filteredOptions.map(key => (
                        <li
                            key={key}
                            onClick={() => handleSelect(key)}
                            className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                        >
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default FormDropDownWithIdInput;