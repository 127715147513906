import React from "react";
import { useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { displayTime, displayShortDate, getCurrentLocalTimeWithOffset } from "../../utils/dateutils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setEtaTab } from "../../redux/reducer/index";

export default function TripTimeline(props) {
  const dispatch = useAppDispatch();
  const etaTab = useAppSelector((state) => state.data.etaTab);
  const [etaCalcs, setEtaCalcs] = useState(props.eta_details && props.eta_details.eta_calculations ? props.eta_details.eta_calculations[etaTab] : {});

  const changeCalc = (tab) => {
    if (props.eta_details && props.eta_details.eta_calculations) {
      dispatch(setEtaTab(tab));
      console.log(props, props.eta_details.eta_calculations[tab])
      setEtaCalcs(props.eta_details.eta_calculations[tab])
    }
  }

  const getTimeDiffJSX = (time1, time2) => {
    if (time1.hours === 0 && time1.minutes === 0 && time2.hours !== 0 && time2.minutes !== 0) {
      return <span className="text-base font-normal text-[#B40808]">Less than driving hours by {time2.hours} hrs {time2.minutes} mins</span>;
    }
    let time1InMinutes = time1.hours * 60 + time1.minutes;
    let time2InMinutes = time2.hours * 60 + time2.minutes;
    let diff = time1InMinutes - time2InMinutes;
    let hours = Math.floor(Math.abs(diff) / 60);
    let minutes = diff % 60;
    if (hours === 0 && minutes === 0) {
      return <span className="text-base font-normal text-[#606479]">0 hrs 0 mins</span>;
    }
    return (<span className={`text-base font-normal ${diff < 0 ? "text-[#B40808]" : "text-blue-700"}`}>{diff > 0 ? "More than driving hours by " : "Less than driving hours by "}{Math.abs(hours)} hrs {Math.abs(minutes)} mins</span>)
  }

  return (
    <>
      {etaCalcs.eta &&
        <div className="p-4 rounded mb-4 bg-gray-50 ">
          {props.eta_details && !props.at_current_destination ? (props.delivery.notes.map((_, index) => (
            <React.Fragment key={`notes_${index}`}>
              {props.eta_details && props.eta_details.eta_calculations &&
                <div className="text-sm mb-4 flex flex-row justify-center items-center">
                  <button onClick={() => changeCalc(0)} className={'py-1 px-4 border rounded-l ' + (etaTab == 0 ? 'bg-[#1A00E2] text-white border-transparent font-semibold' : 'bg-transparent text-[#4F5B67] border-[#D6DDE3]')}>Use Remaining Hours</button>
                  <button onClick={() => changeCalc(1)} className={'py-1 px-4 border rounded-r ' + (etaTab == 1 ? 'bg-[#1A00E2] text-white border-transparent font-semibold' : 'bg-transparent text-[#4F5B67] border-[#D6DDE3]')}>10-hour Break</button>
                </div>
              }
              <div className="text-[#606479] mb-6 text-base">TRIP STATUS</div>
              <div className="ml-4 text-[#606479] flex flex-row">
                <div className="flex flex-col gap-2 basis-[50%]">
                  <div className="text-sm">
                    Arrival is expected:
                  </div>
                  <div>
                    {!etaCalcs.eta ? (
                      <div className="flex flex-row gap-2">Current location is unavailable from ELD. So can’t provide delivery details.</div>
                    ) : etaCalcs.is_late ? (
                      <div className="flex flex-row gap-2 text-[#B40808]"><ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" />Late and after appointment time</div>
                    ) : (
                      <div className="flex flex-row gap-2 text-blue-700"><CheckCircleIcon className="h-6 w-6 text-black" />Early or on-time</div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-sm">
                    Projected Arrival Time (ETA):
                  </div>
                  <div className={`${etaCalcs.is_late ? "text-[#B40808]" : "text-blue-700"}`}>
                    {displayTime(etaCalcs.eta)} {props.delivery_timezone} on {displayShortDate(etaCalcs.eta)}
                  </div>
                  <div className="text-sm">
                    ({etaCalcs.eta_deliverytime_delta.hours} hrs {etaCalcs.eta_deliverytime_delta.minutes} mins {etaCalcs.is_late ? "after" : "before"} appointment time)
                  </div>
                </div>
              </div>
              <hr className="my-8 border-[#4F5B67]" />

              <div className="text-[#606479] mb-6 text-base">TRIP DATA</div>
              <div className="flex flex-col ml-4 text-[#606479] gap-4">
                <div className="flex flex-row w-full">
                  <div className="flex flex-col gap-2 basis-[50%]">
                    <div className="text-sm">
                      Total Time from Now Until Appt. Time
                    </div>
                    <div className="text-lg font-semibold text-[#1C244A]">
                      {etaCalcs.time_to_delivery.hours} hrs {etaCalcs.time_to_delivery.minutes} mins
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="text-sm">
                      Miles to Destination:
                    </div>
                    <div className="text-lg font-semibold text-[#1C244A]">
                      {props.eta_details.drive_miles_remaining} miles
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2 w-1/2">
                  <div className="text-sm">
                    Total Time to Arrive at Stop:
                  </div>
                  <div className="text-lg font-semibold text-[#1C244A]">
                    {etaCalcs.eta_timedetails.hours} hrs {etaCalcs.eta_timedetails.minutes} mins
                  </div>
                  <hr className="border-[#7A7CC0]" />
                  <div className="inline-flex text-base ml-2 text-[#1C244A] justify-between">
                    <div>
                      {etaCalcs.drive_timedetails.hours} hrs {etaCalcs.drive_timedetails.minutes} mins
                    </div>
                    <div>
                      Total Driving Time Needed
                    </div>
                  </div>
                  <hr className="border-[#7A7CC0]" />
                  <div className="inline-flex text-base ml-2 text-[#1C244A] justify-between">
                    <div>
                      {etaCalcs.break_timedetails.hours} hrs {etaCalcs.break_timedetails.minutes} min
                    </div>
                    <div>
                      Total Mandatory Break Time Required
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-2 mt-4">
                  <div className="text-sm">
                    Reason for Delay:
                  </div>
                  <div>
                    {etaCalcs.is_late ? (
                      <div className="text-base text-[#B40808]">Total available time is less than driving + break time by {etaCalcs.eta_deliverytime_delta.hours} hrs {etaCalcs.eta_deliverytime_delta.minutes} mins</div>
                    ) : (
                      <div className="text-base text-[#1A00E2]">Total available time is more than driving + break time by {etaCalcs.eta_deliverytime_delta.hours} hrs {etaCalcs.eta_deliverytime_delta.minutes} mins</div>
                    )}
                  </div>
                </div>
              </div>
              <hr className="my-8 border-[#4F5B67]" />

              {/* <div className="text-[#606479] mb-6 text-base">HOS DATA</div>
              <div className="flex flex-col gap-8">
                <div className="flex flex-row gap-20">
                  <div className="flex flex-col gap-2">
                    <div className="text-[#606479] text-sm">
                      Available HoS Hours During Trip:
                    </div>
                    <div className="text-lg font-semibold">
                      {etaCalcs.time_driven_at_eta.hours} hrs {etaCalcs.time_driven_at_eta.minutes} mins
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="text-[#606479] text-sm">
                      Driving Hours Needed For Trip:
                    </div>
                    <div className="text-lg font-semibold">
                      {etaCalcs.drive_timedetails.hours} hrs {etaCalcs.drive_timedetails.minutes} mins
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-[#606479] text-sm">
                    Available HoS Hours:
                  </div>
                  <div className="text-lg font-semibold">
                    {getTimeDiffJSX(etaCalcs.time_driven_at_eta, etaCalcs.drive_timedetails)}
                  </div>
                </div>
              </div>
              <hr className="my-8 border-[#4F5B67]" /> */}

              <div className="text-[#606479] mb-6 text-base">PROJECTED TRIP TIMELINE</div>
              <div className="inline-flex gap-6 text-[#606479] text-sm">
                <span className="m-auto">Current Time:</span>
                <span className="text-[#4F5B67] text-base m-auto">{displayTime(getCurrentLocalTimeWithOffset(props.local_time_offset))} {props.delivery_timezone} on {displayShortDate(getCurrentLocalTimeWithOffset(props.local_time_offset))}</span>
              </div>
              <div className="mt-6 mb-6 w-11/12">
                <div className="table w-full text-left rtl:text-right">
                  <div className="table-header-group bg-[#EDEDF6] text-sm font-normal rounded-tl-lg rounded-tr-lg">
                    <div className="table-row">
                      <div className="table-cell p-2 text-[#1C244A] rounded-tl-lg">Time bucket</div>
                      <div className="table-cell p-2 text-[#1C244A]">Duration</div>
                      <div className="table-cell p-2 text-[#1C244A]">Start Time/Date</div>
                      <div className="table-cell p-2 text-[#1C244A] rounded-tr-lg">End Time</div>
                    </div>
                  </div>
                  <div className="table-row-group">
                    {etaCalcs.calc_details.map((step, i) => (
                      step.duration &&
                      <div className="table-row bg-transparent" key={i}>
                        <div className={`table-cell text-sm p-2 border-[#A8B0B9] border-l border-b ${etaCalcs.calc_details.length - 1 === i && "rounded-bl-lg"}`}>{step.description}</div>
                        <div className="table-cell text-sm p-2 border-[#A8B0B9] border-b">{step.duration.hours} <span className="text-xs">hrs</span> {step.duration.minutes} <span className="text-xs">mins</span></div>
                        <div className="table-cell text-sm p-2 border-[#A8B0B9] border-b">{displayTime(step.start_time)} on {displayShortDate(step.start_time)} </div>
                        <div className={`table-cell text-sm p-2 border-[#A8B0B9] border-r border-b ${etaCalcs.calc_details.length - 1 === i && "rounded-br-lg"}`}>{displayTime(step.end_time)} on {displayShortDate(step.end_time)}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))) : (
            <>
              <div className="text-[#606479] mb-6 text-base">TRIP STATUS</div>
              <div className="ml-4 text-[#606479] flex flex-row">
                <div className="flex flex-col gap-2 basis-[50%]">
                  <div className="text-sm">
                    {props.at_current_destination ? (
                      <div className="text-[#606479] mb-3">Truck has arrived at destination</div>
                    ) : (
                      <div className="text-[#606479] mb-3">Trip Details not available since location not available from ELD</div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      }
    </>
  )
}