interface FormTextInputProps {
    label: string;
    value: string | number;
    divClassName?: string;
    onChange: (value: any) => void;
  }
  
  const FormTextInput = ({ 
    label, 
    value,
    divClassName="", 
    onChange
  }: FormTextInputProps) => (
    <div className={divClassName}>
        <label className="block text-sm font-medium text-gray-700 mb-1">
            {label}
        </label>

        <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
      </div>
  );
  
  export default FormTextInput;