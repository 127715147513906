import { FlagIcon } from "@heroicons/react/24/solid"
import { fetchData, updateFlag } from '../../redux/reducer';
import { useAppDispatch } from "../../redux/hooks";
import { useEffect, useState } from "react";

export default function FlagException({ flagged, tripId }: { flagged?: boolean; tripId: string }) {
    const dispatch = useAppDispatch();
    const [status, setStatus] = useState(Boolean(flagged));
    const onClick = (flag: boolean) => {
        updateFlag(tripId, flag).then(async (res) => {
            setStatus(flag);
            dispatch(fetchData());
        }).catch((err) => { });
    }
    useEffect(() => {
        setStatus(Boolean(flagged));
    }, [flagged]);
    return (
        <button
            className={`${status ? "bg-[#B40808]" : "bg-[#1A00E2]"} inline-flex gap-2 rounded-sm w-max px-1 p-0.5 text-white text-xs font-semibold uppercase`}
            onClick={() => onClick(!status)}
        >
            <FlagIcon className={`h-4 w-4`} /> {status ? "Is Exception" : "Mark As Exception"}
        </button>
    )
}
