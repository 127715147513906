import React, { useEffect } from "react";
import HomeLayout from "../../components/Layout/homeLayout";
import TripCard from "../../components/ExceptionCards/TripCard";
import useAuthGuard from "../../hooks/useAuthGuard";
import ReeferCard from "../../components/ExceptionCards/ReeferCard";
import DetentionCard from "../../components/ExceptionCards/DetentionCard";
import AvailableTrucksCard from "../../components/ExceptionCards/AvailableTrucksCard";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/solid";

//import H from '@here/maps-api-for-javascript';
//import "@here/maps-api-for-javascript"
//const H = dynamic(() => import('@here/maps-api-for-javascript'))
//import { lazy } from 'react';

export default function Home() {
  const navigate = useNavigate();
  useAuthGuard();
  const { data, loading } = useAppSelector((state) => state.data);
  const [showTemperatureMonitoring, setShowTemperatureMonitoring] = React.useState(false);
  const [showDetention, setShowDetention] = React.useState(false);
  useEffect(() => {
    if (!loading && data.length > 0) {
      let filteredData = data.filter((trip) => Boolean(trip?.temperature_monitoring_details));
      if (filteredData.length > 0) {
        setShowTemperatureMonitoring(true);
      }
      filteredData = data.filter((trip) => Boolean(trip?.detention_summary));
      if (filteredData.length > 0) {
        setShowDetention(true);
      }
    }
  }, [loading, data]);
  let exceptionCards = [
    {
      title: "Trip Monitoring",
      show: true,
      component: <TripCard />,
    }, {
      title: "Temperature Monitoring",
      show: showTemperatureMonitoring,
      component: <ReeferCard />,
    }, {
      title: "Detention Monitoring",
      show: showDetention,
      component: <DetentionCard />,
    }, {
      title: "Available Trucks",
      show: true,
      component: <AvailableTrucksCard />,
    }];
  const getNextExceptionCard = () => {
    do {
      if (exceptionCards.length === 0) {
        return null;
      }
      const card = exceptionCards.shift();
      if (card.show) {
        return card.component;
      }
    } while (true);
  };

  return (
    <HomeLayout>
      <div className="flex justify-between items-center">
      <p className="text-2xl font-semibold text-gray-700 m-2">
        Exception Management
      </p>
      <button 
        onClick={() => navigate('/uploadRateCon')}
        className="flex items-center gap-2 px-3 py-2 bg-[#7A7CC0] text-white rounded-md transition-colors"
      >
        <PlusIcon className="w-4 h-4" /><span>Add Load</span>
      </button>
    </div>
      <hr className="mb-4 border-gray-300" />

      {/*<div className="w-full flex flex-cols-3">
        <div className="w-fit basis-[33%] mx-auto flex justify-center">
          {getNextExceptionCard()}
        </div>
        <div className="w-fit basis-[33%] mx-auto flex justify-center">
          {getNextExceptionCard()}
        </div>
        <div className="w-fit basis-[33%] mx-auto flex justify-center">
          {getNextExceptionCard()}
        </div>
      </div>
      <div className="w-full flex flex-cols-3 gap-4">
        <div className="w-fit basis-[33%] mx-auto flex justify-center">
          {getNextExceptionCard()}
        </div>
      </div>*/}

      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="mx-auto flex justify-center mb-10">
          {getNextExceptionCard()}
        </div>
        <div className="mx-auto flex justify-center mb-10">
          {getNextExceptionCard()}
        </div>
        <div className="mx-auto flex justify-center mb-10">
          {getNextExceptionCard()}
        </div>
        <div className="mx-auto flex justify-center mb-10">
          {getNextExceptionCard()}
        </div>
      </div>
    </HomeLayout>
  );
}
