import { useEffect } from 'react';
  
interface FormDropDownWithIdInputProps {
    label: string;
    value: string;
    onChange: (value: any) => void;
    options?: {};
}

function findBestMatch(input, values) {
    const leven = (a, b) => {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;
    const matrix = Array(a.length + 1).fill().map(() => Array(b.length + 1).fill(0));
    for (let i = 0; i <= a.length; i++) matrix[i][0] = i;
    for (let j = 0; j <= b.length; j++) matrix[0][j] = j;
    for (let i = 1; i <= a.length; i++) {
        for (let j = 1; j <= b.length; j++) {
        matrix[i][j] = Math.min(
            matrix[i-1][j] + 1,
            matrix[i][j-1] + 1,
            matrix[i-1][j-1] + (a[i-1] !== b[j-1] ? 1 : 0)
        );
        }
    }
    return matrix[a.length][b.length];
    };
    
    const match = Object.keys(values).reduce((best, current) => {
    const distance = leven(input ? input.toLowerCase(): "", current ? current.toLowerCase() : "");
    return distance < best.distance ? {name: current, distance} : best;
    }, {name: '', distance: Infinity});

    return match.name;
}

const FormDropDownWithIdInput = ({ label, value, onChange, options }: FormDropDownWithIdInputProps) => {
    var bestMatch = findBestMatch(value, options);

    useEffect(() => {
        onChange(bestMatch);
    }, [bestMatch]);

    return (
    <div className="">
        <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
        </label>
        <select
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={bestMatch}
        onChange={(e) => onChange(e.target.value)}
        >
        {Object.entries(options || {}).map(([key, val]) => (
            <option key={key} value={key}>
            {key.charAt(0).toUpperCase() + key.slice(1)}
            </option>
        ))}
        </select>
    </div>
    );
};

export default FormDropDownWithIdInput;