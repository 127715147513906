import React from "react";
import { ClockIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

interface CardProps {
  title: string;
  tripData: {
    [key: string]: {
      [key: string]: number;
    };
  };
  links: {
    [key: string]: {
      [key: string]: string;
    };
  };
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  image?: any;
}

const Card: React.FC<CardProps> = ({ title, tripData, links, image, icon: Icon = ClockIcon }) => {
  const navigate = useNavigate();
  return (
    <div className="bg-white rounded-lg shadow p-4 w-max">
      <div className="flex items-center mb-1">
        {!image && <Icon className="h-6 w-6 text-gray-500" />}
        {image}
        <span className="ml-2 text-xl font-semibold text-gray-700">
          {title}
        </span>
      </div>
      <div className="mb-4 bg-gray-300 h-px" />
      <div className="min-w-[300]">
        {Object.keys(tripData).map((title: string, i) => (
          <div className="mb-4" key={`trip_data_${i}`}>
            <div className="text-gray-700 mb-2">{title}</div>
            {Object.keys(tripData[title]).map((key: string, index) => (
              <p className="mb-2" key={`trip_data_${title}_${index}`}>
                <span
                  onClick={() => {
                    navigate(links[title][key]);
                  }}
                  className="ml-3 text-blue-600 cursor-pointer border-b border-blue-600"
                >
                  {key} ({tripData[title][key]})
                </span>
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
