import { useAppDispatch } from '../../redux/hooks';
import { addManualLoad } from '../../redux/reducer';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HomeLayout from '../Layout/homeLayout';
import Modal from "react-modal";

const drivers = {
    "Pablo Artemio Garcia Barrales": { phone: "5107761281", truck:"2101121", trailer:"R15118" },
    "Jose Chavez Castaneda": {phone: "2096046072", truck:"4100320", trailer:"R13518"},
    "Isaac Garcia Sr": { phone: "5622577605", truck:"2101621", trailer:"R12317" },
    "Alvin Jones": { phone: "6614701503", truck:"23200", trailer:"R11017" },
    "Thomas Ruby": { phone: "5107761281", truck: "2100820", trailer: "MT1659" },
    "Joseph Tapia": { phone: "6614780039", truck: "2100921", trailer: "R11717" },
  };

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#EDEDF6',
  },
};

const createLoad = (rateCon) => {
    var stops = []
    rateCon.stops.forEach(function (stop, i) {
        stops.push({
            "id": "954e30431f4f4dd193c76ab04b23aa3b",
            "stop_order": stop.stop_number,
            "location": {
              "address1": stop.address,
              "city": stop.city,
              "state": stop.state,
              "zipcode": stop.zip,
              "lat": stop.lat,
              "long": stop.lng
            },
            "deliver_date": stop.appointment_date,
            "stop_type": stop.stop_type,
            "deliver_status": "Covered",
            "deliver_notes": "",
            "eta": "2024-11-04 12:00:00+00:00",
            "customer_name": stop.company_name,
            "appointment": stop.appointment_start_time,
            "appointment_type": stop.appointment_type
          })
        })

    return {
        "client_id": "TA492",
        "trip_num": rateCon.order_number,
        "load_num": rateCon.order_number,
        "trip_status": "In Transit",
        "trailer_num": rateCon.carrier.trailer,
        "truck_num": rateCon.carrier.truck,
        "driver": {
          "driver_name": rateCon.carrier.driver_name,
          "contact_phone": rateCon.carrier.driver_phone,
          "samsara_id": "50463763",
          "hos": [
            {
              "future_day": 0,
              "hours": 0,
              "minutes": 0
            },
            {
              "future_day": 1,
              "hours": 12,
              "minutes": 49
            },
            {
              "future_day": 2,
              "hours": 8,
              "minutes": 48
            },
            {
              "future_day": 3,
              "hours": 10,
              "minutes": 51
            },
            {
              "future_day": 4,
              "hours": 12,
              "minutes": 4
            },
            {
              "future_day": 5,
              "hours": 7,
              "minutes": 50
            },
            {
              "future_day": 6,
              "hours": 10,
              "minutes": 34
            },
            {
              "future_day": 7,
              "hours": 7,
              "minutes": 4
            }
          ],
          "hos_used": [
            {
              "future_day": 0,
              "hours": 62,
              "minutes": 38
            },
            {
              "future_day": -1,
              "hours": 0,
              "minutes": 0
            },
            {
              "future_day": 0,
              "hours": 7,
              "minutes": 4
            },
            {
              "future_day": 1,
              "hours": 17,
              "minutes": 38
            },
            {
              "future_day": 2,
              "hours": 25,
              "minutes": 28
            },
            {
              "future_day": 3,
              "hours": 37,
              "minutes": 32
            },
            {
              "future_day": 4,
              "hours": 48,
              "minutes": 23
            },
            {
              "future_day": 5,
              "hours": 57,
              "minutes": 11
            }
          ],
          "hos_remaining": [
            {
              "future_day": -1,
              "hours": 70,
              "minutes": 0
            },
            {
              "future_day": 0,
              "hours": 62,
              "minutes": 56
            },
            {
              "future_day": 1,
              "hours": 52,
              "minutes": 22
            },
            {
              "future_day": 2,
              "hours": 44,
              "minutes": 32
            },
            {
              "future_day": 3,
              "hours": 32,
              "minutes": 28
            },
            {
              "future_day": 4,
              "hours": 21,
              "minutes": 37
            },
            {
              "future_day": 5,
              "hours": 12,
              "minutes": 49
            }
          ],
          "hos_calc_remaining": [
            {
              "day": 1,
              "day_calc_details": [
                {
                  "day": 1,
                  "day_description": "Today - 7",
                  "date": "2024-10-06 17:11:29.283000+00:00",
                  "shift_hours": {
                    "hours": 8,
                    "minutes": 48
                  },
                  "hours_used": {
                    "hours": 8,
                    "minutes": 48
                  },
                  "hours_remaining": {
                    "hours": 61,
                    "minutes": 12
                  }
                },
                {
                  "day": 2,
                  "day_description": "Today - 6",
                  "date": "2024-10-07 17:11:29.283000+00:00",
                  "shift_hours": {
                    "hours": 10,
                    "minutes": 51
                  },
                  "hours_used": {
                    "hours": 19,
                    "minutes": 39
                  },
                  "hours_remaining": {
                    "hours": 50,
                    "minutes": 21
                  }
                },
                {
                  "day": 3,
                  "day_description": "Today - 5",
                  "date": "2024-10-08 17:11:29.283000+00:00",
                  "shift_hours": {
                    "hours": 12,
                    "minutes": 4
                  },
                  "hours_used": {
                    "hours": 31,
                    "minutes": 43
                  },
                  "hours_remaining": {
                    "hours": 38,
                    "minutes": 17
                  }
                },
                {
                  "day": 4,
                  "day_description": "Today - 4",
                  "date": "2024-10-09 17:11:29.283000+00:00",
                  "shift_hours": {
                    "hours": 7,
                    "minutes": 50
                  },
                  "hours_used": {
                    "hours": 39,
                    "minutes": 33
                  },
                  "hours_remaining": {
                    "hours": 30,
                    "minutes": 27
                  }
                },
                {
                  "day": 5,
                  "day_description": "Today - 3",
                  "date": "2024-10-10 17:11:29.283000+00:00",
                  "shift_hours": {
                    "hours": 10,
                    "minutes": 34
                  },
                  "hours_used": {
                    "hours": 50,
                    "minutes": 7
                  },
                  "hours_remaining": {
                    "hours": 19,
                    "minutes": 53
                  }
                },
                {
                  "day": 6,
                  "day_description": "Today - 2",
                  "date": "2024-10-11 17:11:29.283000+00:00",
                  "shift_hours": {
                    "hours": 7,
                    "minutes": 4
                  },
                  "hours_used": {
                    "hours": 57,
                    "minutes": 11
                  },
                  "hours_remaining": {
                    "hours": 12,
                    "minutes": 49
                  }
                },
                {
                  "day": 7,
                  "day_description": "Today - 1",
                  "date": "2024-10-12 17:11:29.283000+00:00",
                  "shift_hours": {
                    "hours": 0,
                    "minutes": 0
                  },
                  "hours_used": {
                    "hours": 57,
                    "minutes": 11
                  },
                  "hours_remaining": {
                    "hours": 12,
                    "minutes": 49
                  }
                },
                {
                  "day": 8,
                  "day_description": "Today",
                  "date": "2024-10-13 17:11:29.283000+00:00",
                  "shift_hours": {
                    "hours": 12,
                    "minutes": 49
                  },
                  "hours_used": {
                    "hours": 70,
                    "minutes": 0
                  },
                  "hours_remaining": {
                    "hours": 0,
                    "minutes": 0
                  }
                }
              ]
            },
            {
              "day": 2,
              "day_calc_details": [
                {
                  "day": 1,
                  "day_description": "Today - 7",
                  "date": "2024-10-07 17:11:29.303000+00:00",
                  "shift_hours": {
                    "hours": 10,
                    "minutes": 51
                  },
                  "hours_used": {
                    "hours": 10,
                    "minutes": 51
                  },
                  "hours_remaining": {
                    "hours": 59,
                    "minutes": 9
                  }
                },
                {
                  "day": 2,
                  "day_description": "Today - 6",
                  "date": "2024-10-08 17:11:29.303000+00:00",
                  "shift_hours": {
                    "hours": 12,
                    "minutes": 4
                  },
                  "hours_used": {
                    "hours": 22,
                    "minutes": 55
                  },
                  "hours_remaining": {
                    "hours": 47,
                    "minutes": 5
                  }
                },
                {
                  "day": 3,
                  "day_description": "Today - 5",
                  "date": "2024-10-09 17:11:29.303000+00:00",
                  "shift_hours": {
                    "hours": 7,
                    "minutes": 50
                  },
                  "hours_used": {
                    "hours": 30,
                    "minutes": 45
                  },
                  "hours_remaining": {
                    "hours": 39,
                    "minutes": 15
                  }
                },
                {
                  "day": 4,
                  "day_description": "Today - 4",
                  "date": "2024-10-10 17:11:29.303000+00:00",
                  "shift_hours": {
                    "hours": 10,
                    "minutes": 34
                  },
                  "hours_used": {
                    "hours": 41,
                    "minutes": 19
                  },
                  "hours_remaining": {
                    "hours": 28,
                    "minutes": 41
                  }
                },
                {
                  "day": 5,
                  "day_description": "Today - 3",
                  "date": "2024-10-11 17:11:29.303000+00:00",
                  "shift_hours": {
                    "hours": 7,
                    "minutes": 4
                  },
                  "hours_used": {
                    "hours": 48,
                    "minutes": 23
                  },
                  "hours_remaining": {
                    "hours": 21,
                    "minutes": 37
                  }
                },
                {
                  "day": 6,
                  "day_description": "Today - 2",
                  "date": "2024-10-12 17:11:29.303000+00:00",
                  "shift_hours": {
                    "hours": 0,
                    "minutes": 0
                  },
                  "hours_used": {
                    "hours": 48,
                    "minutes": 23
                  },
                  "hours_remaining": {
                    "hours": 21,
                    "minutes": 37
                  }
                },
                {
                  "day": 7,
                  "day_description": "Today - 1",
                  "date": "2024-10-13 17:11:29.303000+00:00",
                  "shift_hours": {
                    "hours": 12,
                    "minutes": 49
                  },
                  "hours_used": {
                    "hours": 61,
                    "minutes": 12
                  },
                  "hours_remaining": {
                    "hours": 8,
                    "minutes": 48
                  }
                },
                {
                  "day": 8,
                  "day_description": "Today",
                  "date": "2024-10-14 17:11:29.303000+00:00",
                  "shift_hours": {
                    "hours": 8,
                    "minutes": 48
                  },
                  "hours_used": {
                    "hours": 70,
                    "minutes": 0
                  },
                  "hours_remaining": {
                    "hours": 0,
                    "minutes": 0
                  }
                }
              ]
            },
            {
              "day": 3,
              "day_calc_details": [
                {
                  "day": 1,
                  "day_description": "Today - 7",
                  "date": "2024-10-08 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 12,
                    "minutes": 4
                  },
                  "hours_used": {
                    "hours": 12,
                    "minutes": 4
                  },
                  "hours_remaining": {
                    "hours": 57,
                    "minutes": 56
                  }
                },
                {
                  "day": 2,
                  "day_description": "Today - 6",
                  "date": "2024-10-09 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 7,
                    "minutes": 50
                  },
                  "hours_used": {
                    "hours": 19,
                    "minutes": 54
                  },
                  "hours_remaining": {
                    "hours": 50,
                    "minutes": 6
                  }
                },
                {
                  "day": 3,
                  "day_description": "Today - 5",
                  "date": "2024-10-10 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 10,
                    "minutes": 34
                  },
                  "hours_used": {
                    "hours": 30,
                    "minutes": 28
                  },
                  "hours_remaining": {
                    "hours": 39,
                    "minutes": 32
                  }
                },
                {
                  "day": 4,
                  "day_description": "Today - 4",
                  "date": "2024-10-11 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 7,
                    "minutes": 4
                  },
                  "hours_used": {
                    "hours": 37,
                    "minutes": 32
                  },
                  "hours_remaining": {
                    "hours": 32,
                    "minutes": 28
                  }
                },
                {
                  "day": 5,
                  "day_description": "Today - 3",
                  "date": "2024-10-12 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 0,
                    "minutes": 0
                  },
                  "hours_used": {
                    "hours": 37,
                    "minutes": 32
                  },
                  "hours_remaining": {
                    "hours": 32,
                    "minutes": 28
                  }
                },
                {
                  "day": 6,
                  "day_description": "Today - 2",
                  "date": "2024-10-13 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 12,
                    "minutes": 49
                  },
                  "hours_used": {
                    "hours": 50,
                    "minutes": 21
                  },
                  "hours_remaining": {
                    "hours": 19,
                    "minutes": 39
                  }
                },
                {
                  "day": 7,
                  "day_description": "Today - 1",
                  "date": "2024-10-14 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 8,
                    "minutes": 48
                  },
                  "hours_used": {
                    "hours": 59,
                    "minutes": 9
                  },
                  "hours_remaining": {
                    "hours": 10,
                    "minutes": 51
                  }
                },
                {
                  "day": 8,
                  "day_description": "Today",
                  "date": "2024-10-15 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 10,
                    "minutes": 51
                  },
                  "hours_used": {
                    "hours": 70,
                    "minutes": 0
                  },
                  "hours_remaining": {
                    "hours": 0,
                    "minutes": 0
                  }
                }
              ]
            },
            {
              "day": 4,
              "day_calc_details": [
                {
                  "day": 1,
                  "day_description": "Today - 7",
                  "date": "2024-10-09 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 7,
                    "minutes": 50
                  },
                  "hours_used": {
                    "hours": 7,
                    "minutes": 50
                  },
                  "hours_remaining": {
                    "hours": 62,
                    "minutes": 10
                  }
                },
                {
                  "day": 2,
                  "day_description": "Today - 6",
                  "date": "2024-10-10 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 10,
                    "minutes": 34
                  },
                  "hours_used": {
                    "hours": 18,
                    "minutes": 24
                  },
                  "hours_remaining": {
                    "hours": 51,
                    "minutes": 36
                  }
                },
                {
                  "day": 3,
                  "day_description": "Today - 5",
                  "date": "2024-10-11 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 7,
                    "minutes": 4
                  },
                  "hours_used": {
                    "hours": 25,
                    "minutes": 28
                  },
                  "hours_remaining": {
                    "hours": 44,
                    "minutes": 32
                  }
                },
                {
                  "day": 4,
                  "day_description": "Today - 4",
                  "date": "2024-10-12 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 0,
                    "minutes": 0
                  },
                  "hours_used": {
                    "hours": 25,
                    "minutes": 28
                  },
                  "hours_remaining": {
                    "hours": 44,
                    "minutes": 32
                  }
                },
                {
                  "day": 5,
                  "day_description": "Today - 3",
                  "date": "2024-10-13 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 12,
                    "minutes": 49
                  },
                  "hours_used": {
                    "hours": 38,
                    "minutes": 17
                  },
                  "hours_remaining": {
                    "hours": 31,
                    "minutes": 43
                  }
                },
                {
                  "day": 6,
                  "day_description": "Today - 2",
                  "date": "2024-10-14 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 8,
                    "minutes": 48
                  },
                  "hours_used": {
                    "hours": 47,
                    "minutes": 5
                  },
                  "hours_remaining": {
                    "hours": 22,
                    "minutes": 55
                  }
                },
                {
                  "day": 7,
                  "day_description": "Today - 1",
                  "date": "2024-10-15 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 10,
                    "minutes": 51
                  },
                  "hours_used": {
                    "hours": 57,
                    "minutes": 56
                  },
                  "hours_remaining": {
                    "hours": 12,
                    "minutes": 4
                  }
                },
                {
                  "day": 8,
                  "day_description": "Today",
                  "date": "2024-10-16 17:11:29.304000+00:00",
                  "shift_hours": {
                    "hours": 12,
                    "minutes": 4
                  },
                  "hours_used": {
                    "hours": 70,
                    "minutes": 0
                  },
                  "hours_remaining": {
                    "hours": 0,
                    "minutes": 0
                  }
                }
              ]
            },
            {
              "day": 5,
              "day_calc_details": [
                {
                  "day": 1,
                  "day_description": "Today - 7",
                  "date": "2024-10-10 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 10,
                    "minutes": 34
                  },
                  "hours_used": {
                    "hours": 10,
                    "minutes": 34
                  },
                  "hours_remaining": {
                    "hours": 59,
                    "minutes": 26
                  }
                },
                {
                  "day": 2,
                  "day_description": "Today - 6",
                  "date": "2024-10-11 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 7,
                    "minutes": 4
                  },
                  "hours_used": {
                    "hours": 17,
                    "minutes": 38
                  },
                  "hours_remaining": {
                    "hours": 52,
                    "minutes": 22
                  }
                },
                {
                  "day": 3,
                  "day_description": "Today - 5",
                  "date": "2024-10-12 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 0,
                    "minutes": 0
                  },
                  "hours_used": {
                    "hours": 17,
                    "minutes": 38
                  },
                  "hours_remaining": {
                    "hours": 52,
                    "minutes": 22
                  }
                },
                {
                  "day": 4,
                  "day_description": "Today - 4",
                  "date": "2024-10-13 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 12,
                    "minutes": 49
                  },
                  "hours_used": {
                    "hours": 30,
                    "minutes": 27
                  },
                  "hours_remaining": {
                    "hours": 39,
                    "minutes": 33
                  }
                },
                {
                  "day": 5,
                  "day_description": "Today - 3",
                  "date": "2024-10-14 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 8,
                    "minutes": 48
                  },
                  "hours_used": {
                    "hours": 39,
                    "minutes": 15
                  },
                  "hours_remaining": {
                    "hours": 30,
                    "minutes": 45
                  }
                },
                {
                  "day": 6,
                  "day_description": "Today - 2",
                  "date": "2024-10-15 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 10,
                    "minutes": 51
                  },
                  "hours_used": {
                    "hours": 50,
                    "minutes": 6
                  },
                  "hours_remaining": {
                    "hours": 19,
                    "minutes": 54
                  }
                },
                {
                  "day": 7,
                  "day_description": "Today - 1",
                  "date": "2024-10-16 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 12,
                    "minutes": 4
                  },
                  "hours_used": {
                    "hours": 62,
                    "minutes": 10
                  },
                  "hours_remaining": {
                    "hours": 7,
                    "minutes": 50
                  }
                },
                {
                  "day": 8,
                  "day_description": "Today",
                  "date": "2024-10-17 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 7,
                    "minutes": 50
                  },
                  "hours_used": {
                    "hours": 70,
                    "minutes": 0
                  },
                  "hours_remaining": {
                    "hours": 0,
                    "minutes": 0
                  }
                }
              ]
            },
            {
              "day": 6,
              "day_calc_details": [
                {
                  "day": 1,
                  "day_description": "Today - 7",
                  "date": "2024-10-11 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 7,
                    "minutes": 4
                  },
                  "hours_used": {
                    "hours": 7,
                    "minutes": 4
                  },
                  "hours_remaining": {
                    "hours": 62,
                    "minutes": 56
                  }
                },
                {
                  "day": 2,
                  "day_description": "Today - 6",
                  "date": "2024-10-12 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 0,
                    "minutes": 0
                  },
                  "hours_used": {
                    "hours": 7,
                    "minutes": 4
                  },
                  "hours_remaining": {
                    "hours": 62,
                    "minutes": 56
                  }
                },
                {
                  "day": 3,
                  "day_description": "Today - 5",
                  "date": "2024-10-13 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 12,
                    "minutes": 49
                  },
                  "hours_used": {
                    "hours": 19,
                    "minutes": 53
                  },
                  "hours_remaining": {
                    "hours": 50,
                    "minutes": 7
                  }
                },
                {
                  "day": 4,
                  "day_description": "Today - 4",
                  "date": "2024-10-14 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 8,
                    "minutes": 48
                  },
                  "hours_used": {
                    "hours": 28,
                    "minutes": 41
                  },
                  "hours_remaining": {
                    "hours": 41,
                    "minutes": 19
                  }
                },
                {
                  "day": 5,
                  "day_description": "Today - 3",
                  "date": "2024-10-15 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 10,
                    "minutes": 51
                  },
                  "hours_used": {
                    "hours": 39,
                    "minutes": 32
                  },
                  "hours_remaining": {
                    "hours": 30,
                    "minutes": 28
                  }
                },
                {
                  "day": 6,
                  "day_description": "Today - 2",
                  "date": "2024-10-16 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 12,
                    "minutes": 4
                  },
                  "hours_used": {
                    "hours": 51,
                    "minutes": 36
                  },
                  "hours_remaining": {
                    "hours": 18,
                    "minutes": 24
                  }
                },
                {
                  "day": 7,
                  "day_description": "Today - 1",
                  "date": "2024-10-17 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 7,
                    "minutes": 50
                  },
                  "hours_used": {
                    "hours": 59,
                    "minutes": 26
                  },
                  "hours_remaining": {
                    "hours": 10,
                    "minutes": 34
                  }
                },
                {
                  "day": 8,
                  "day_description": "Today",
                  "date": "2024-10-18 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 10,
                    "minutes": 34
                  },
                  "hours_used": {
                    "hours": 70,
                    "minutes": 0
                  },
                  "hours_remaining": {
                    "hours": 0,
                    "minutes": 0
                  }
                }
              ]
            },
            {
              "day": 7,
              "day_calc_details": [
                {
                  "day": 1,
                  "day_description": "Today - 7",
                  "date": "2024-10-12 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 0,
                    "minutes": 0
                  },
                  "hours_used": {
                    "hours": 0,
                    "minutes": 0
                  },
                  "hours_remaining": {
                    "hours": 70,
                    "minutes": 0
                  }
                },
                {
                  "day": 2,
                  "day_description": "Today - 6",
                  "date": "2024-10-13 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 12,
                    "minutes": 49
                  },
                  "hours_used": {
                    "hours": 12,
                    "minutes": 49
                  },
                  "hours_remaining": {
                    "hours": 57,
                    "minutes": 11
                  }
                },
                {
                  "day": 3,
                  "day_description": "Today - 5",
                  "date": "2024-10-14 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 8,
                    "minutes": 48
                  },
                  "hours_used": {
                    "hours": 21,
                    "minutes": 37
                  },
                  "hours_remaining": {
                    "hours": 48,
                    "minutes": 23
                  }
                },
                {
                  "day": 4,
                  "day_description": "Today - 4",
                  "date": "2024-10-15 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 10,
                    "minutes": 51
                  },
                  "hours_used": {
                    "hours": 32,
                    "minutes": 28
                  },
                  "hours_remaining": {
                    "hours": 37,
                    "minutes": 32
                  }
                },
                {
                  "day": 5,
                  "day_description": "Today - 3",
                  "date": "2024-10-16 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 12,
                    "minutes": 4
                  },
                  "hours_used": {
                    "hours": 44,
                    "minutes": 32
                  },
                  "hours_remaining": {
                    "hours": 25,
                    "minutes": 28
                  }
                },
                {
                  "day": 6,
                  "day_description": "Today - 2",
                  "date": "2024-10-17 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 7,
                    "minutes": 50
                  },
                  "hours_used": {
                    "hours": 52,
                    "minutes": 22
                  },
                  "hours_remaining": {
                    "hours": 17,
                    "minutes": 38
                  }
                },
                {
                  "day": 7,
                  "day_description": "Today - 1",
                  "date": "2024-10-18 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 10,
                    "minutes": 34
                  },
                  "hours_used": {
                    "hours": 62,
                    "minutes": 56
                  },
                  "hours_remaining": {
                    "hours": 7,
                    "minutes": 4
                  }
                },
                {
                  "day": 8,
                  "day_description": "Today",
                  "date": "2024-10-19 17:11:29.305000+00:00",
                  "shift_hours": {
                    "hours": 7,
                    "minutes": 4
                  },
                  "hours_used": {
                    "hours": 70,
                    "minutes": 0
                  },
                  "hours_remaining": {
                    "hours": 0,
                    "minutes": 0
                  }
                }
              ]
            }
          ],
          "legal_time_status": {
            "duty_status": "sleeperBed",
            "time_in_current_status": {
              "hours": 62,
              "minutes": 37
            },
            "time_until_break": {
              "hours": 8,
              "minutes": 0
            },
            "drive_remaining": {
              "hours": 7,
              "minutes": 22
            },
            "shift_remaining": {
              "hours": 7,
              "minutes": 22
            },
            "days_since_last_reset": 7.1,
            "cycle_remaining": {
              "hours": 7,
              "minutes": 22
            },
            "cycles_future_days": [
              {
                "future_day": 0,
                "hours": 7,
                "minutes": 22
              },
              {
                "future_day": 1,
                "hours": 11,
                "minutes": 0
              },
              {
                "future_day": 2,
                "hours": 8,
                "minutes": 48
              },
              {
                "future_day": 3,
                "hours": 10,
                "minutes": 51
              },
              {
                "future_day": 4,
                "hours": 11,
                "minutes": 0
              },
              {
                "future_day": 5,
                "hours": 7,
                "minutes": 50
              },
              {
                "future_day": 6,
                "hours": 10,
                "minutes": 34
              },
              {
                "future_day": 7,
                "hours": 7,
                "minutes": 4
              }
            ]
          },
          "break_status": [
            {
              "hours": 8,
              "minutes": 0
            },
            {
              "future_day": 1,
              "hours": 7,
              "minutes": 0
            },
            {
              "future_day": 2,
              "hours": 7,
              "minutes": 0
            },
            {
              "future_day": 3,
              "hours": 7,
              "minutes": 0
            },
            {
              "future_day": 4,
              "hours": 7,
              "minutes": 0
            },
            {
              "future_day": 5,
              "hours": 7,
              "minutes": 0
            },
            {
              "future_day": 6,
              "hours": 7,
              "minutes": 0
            },
            {
              "future_day": 7,
              "hours": 7,
              "minutes": 0
            }
          ],
          "offduty_status": {
            "current_status": "sleeperBed",
            "is_off_duty": false,
            "off_duty_start_time": "2024-10-12 06:11:23.684000+00:00",
            "off_duty_end_time": "2024-10-12 16:11:23.684000+00:00",
            "current_break_start_time": "2024-10-12 06:11:23.684000+00:00",
            "current_break_end_time": "2024-10-12 16:11:23.684000+00:00"
          }
        },
        "stops": stops,
        "active_stop_num": 1,
        "current_location": {
          "address1": rateCon.truck_location.address,
          "city": rateCon.truck_location.address,
          "lat": rateCon.truck_location.lat,
          "long": rateCon.truck_location.lng,
          "last_updated": "2024-11-04 10:05:14+00:00"
        },
        "destination_location": {
          "lat": 33.44409,
          "long": -112.276776
        },
        "customer_name": rateCon.customer_name,
        "delivery_offset_time": -480,
        "deliver_date": rateCon.stops[1].appointment_date,
        "delivery_timezone": "PST",
        "eta_details": {
          "drive_miles_remaining": 50,
          "eta": "2024-11-04 11:31:00+00:00",
          "eta_timedetails": {
            "hours": 1,
            "minutes": 5
          },
          "is_late": false,
          "eta_calculations": [
            {
              "eta": "2024-11-04 11:31:00+00:00",
              "eta_timedetails": {
                "hours": 1,
                "minutes": 5
              },
              "drive_timedetails": {
                "hours": 1,
                "minutes": 5
              },
              "break_timedetails": {
                "hours": 0,
                "minutes": 0
              },
              "is_late": false,
              "trip_details_description": "50 miles and 1 hours 5 minutes left to destination",
              "eta_currenttime_delta": {
                "hours": 1,
                "minutes": 5
              },
              "eta_deliverytime_delta": {
                "hours": 1,
                "minutes": 5
              },
              "time_remaining_at_eta": {
                "hours": 1,
                "minutes": 5
              },
              "time_driven_at_eta": {
                "hours": 9,
                "minutes": 49
              },
              "is_late_and_delivery_due": false,
              "time_to_delivery": {
                "hours": 1,
                "minutes": 5
              },
              "calc_details": [
                {
                  "order": 1,
                  "start_time": "2024-11-04 10:05:00+00:00",
                  "end_time": "2024-10-12 11:10:00+00:00",
                  "duration": {
                    "hours": 1,
                    "minutes": 5
                  },
                  "description": "Driving",
                  "step_type": "Driving"
                }
              ]
            },
            {
              "eta": "2024-11-04 11:31:00+00:00",
              "eta_timedetails": {
                "hours": 1,
                "minutes": 5
              },
              "drive_timedetails": {
                "hours": 1,
                "minutes": 5
              },
              "break_timedetails": {
                "hours": 0,
                "minutes": 0
              },
              "is_late": false,
              "trip_details_description": "50 miles and 1 hours 5 minutes left to destination",
              "eta_currenttime_delta": {
                "hours": 1,
                "minutes": 5
              },
              "eta_deliverytime_delta": {
                "hours": 0,
                "minutes": 31
              },
              "time_remaining_at_eta": {
                "hours": 0,
                "minutes": 31
              },
              "time_driven_at_eta": {
                "hours": 1,
                "minutes": 5
              },
              "is_late_and_delivery_due": false,
              "time_to_delivery": {
                "hours": 1,
                "minutes": 5
              },
              "calc_details": [
                {
                  "order": 1,
                  "start_time": "2024-11-04 10:05:00+00:00",
                  "end_time": "2024-11-04 11:10:00+00:00",
                  "duration": {
                    "hours": 1,
                    "minutes": 5
                  },
                  "description": "Driving",
                  "step_type": "Driving"
                }
              ]
            }
          ]
        },
        "at_current_destination": false,
        "detention": [],
        "external_tms_summary": {
          "tms_name": "Spoton",
          "external_link": "https://app.alvys.com/#/loads/1013128"
        }
      }
}

const RateConfirmationPage = () => {
  const dispatch = useAppDispatch();
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  var [data, setData] = useState({
    "order_number": "",
    "customer_name": "",
    "phone": "",
    "email": "",
    "linehaul_rate": 0,
    "fuel_surcharge": 0,
    "weight": 0,
    "equipment_type": "reefer",
    "temperature_control": "",
    "temperature": 0,
    "carrier": {
      "carrier_name": "",
      "driver_name": "",
      "driver_phone": "",
      "truck":"",
      "trailer":""
    },
    "truck_location": {
        "address": "",
        "lat": 0,
        "lng": 0,
    },
    "stops": [
      {
        "stop_number": 1,
        "company_name": "",
        "address": "",
        "city": "",
        "state": "",
        "zipcode": "",
        "lat": 0,
        "lng": 0,
        "phone": "",
        "email": "",
        "appointment_type": "",
        "appointment_date": "",
        "appointment_start_time": "",
        "appointment_end_time": "",
        "stop_type": "",
        "stop_instructions": ""
      }
    ]
  })

  if (params.get("file") == "6062625.pdf" && !data.order_number) {
    setData({
      "order_number": "6062625",
      "customer_name": "ARRIVE LOGISTICS",
      "phone": "8888610650",
      "email": "invoices@arrivelogistics.com",
      "linehaul_rate": 550,
      "fuel_surcharge": 0,
      "weight": 43326,
      "equipment_type": "reefer",
      "temperature_control": "",
      "temperature": 0,
      "carrier": {
        "carrier_name": "Tamana Truck Lines",
        "driver_name": "",
        "driver_phone": "",
        "truck":"",
        "trailer":""
      },
      "truck_location": {
        "address":"Phoenix, AZ",
        "lat": 33.6049777,
        "lng": -112.4547037,
    },
      "stops": [
        {
          "stop_number": 1,
          "company_name": "MARK ANTHONY WHSE",
          "address": "9610 N REEMS RD",
          "city": "Waddell",
          "state": "AZ",
          "zipcode": "85355",
          "lat":33.5718003,
          "lng":-112.3969487,
          "phone": "",
          "email": "",
          "appointment_type": "APPT",
          "appointment_date": "2024-11-04T09:00:00",
          "appointment_start_time": "",
          "appointment_end_time": "",
          "stop_type": "Pickup",
          "stop_instructions": "Truck entrance is on the North side of the building off Reems Rd. -- DO NOT ENTE R FROM HATCHER.Office number 480-604-8903Open 24/7 Monday 0000 - Sunday 2300.PLE ASE READ THE ATTACHED LOAD DOCUMENT FOR TRAILER QUALITY REQUIREMENTS. INADEQUATE EQUIPMENT WILL BE TURNED AWAY.DRIVERS WHO ARE 2 HOURS OR MORE PAST SCHEDULED AP POINTMENT TIME MUST RESCHEDULE LOAD APPOINTMENTEARLY DRIVERS CAN CHECK IN 30-45 MINS MAX PRIOR TO APPOINTMENT TIME"
        },
        {
          "stop_number": 2,
          "company_name": "Allied Beverages",
          "address": "5801 Nicolle St",
          "city": "Ventura",
          "state": "CA",
          "zipcode": "93003",
          "lat":34.2480546,
          "lng":-119.2288833,
          "phone": "",
          "email": "",
          "appointment_type": "APPT",
          "appointment_date": "2024-11-05T08:00:00",
          "appointment_start_time": "",
          "appointment_end_time": "",
          "stop_type": "Delivery",
          "stop_instructions": "Use the Shipment Ref# in TMS as the RBD PO# for scheduling within 3rd Party. If the order in TMS has 2 shipmt reference #s, enter both in the RBD PO# search bar to schedule them together. When reaching out for appointment issues or requests carrier' s must include their - RBD PO# (S#)- Warehouse/supplier- Appt date/time - SCAC CodeScheduling RequestsPlease reach out to RBD@Coyote.com and rbdappts@re yesholdings.com, if there are no appointment availabilities in the E2Open 3rd pa rty portal, or if you are having any scheduling related issues.If your drivers h ave any delays/ETAs/Work-in requests, please reach out directly to the warehouse emails. If delays occur afterhours and the load is no longer GFTOD, reach out t o the warehouse and CC in MHLafterhours@blujaysolutions.co Reefer unit can' t be on roof of trailer. No roll doors. Driver to ensure they are given a seal by the shipper & apply before leaving, not doing so could result in the load being rejected at del. $350 fine for not applying seal before leaving shipper Driver not to stop within 50 miles of delivery, if yarded, must be stored in well-lit, secured area with 24/7 monitoring and security. Accessorials must be requested within 24hrs of delivery."
        }
      ]
    });
  } else if (params.get("file") == "12140318.pdf" && !data.order_number) {
    setData({
      "order_number": "12140318",
      "customer_name": "LAMB WESTON SALES INC",
      "phone": "5093712800",
      "email": "d1shipping@lineagelogistics.com",
      "linehaul_rate": 1654.40,
      "fuel_surcharge": 0,
      "weight": 43056,
      "equipment_type": "reefer",
      "temperature_control": "",
      "temperature": 0,
      "carrier": {
        "carrier_name": "TAMANA TRUCK LINES",
        "driver_name": "",
        "driver_phone": "2095386949",
        "truck":"",
        "trailer":""
      },
      "truck_location": {
        "address":"Richland, WA",
        "lat": 46.282088,
        "lng": -119.4706903,
    },
      "stops": [
        {
          "stop_number": 1,
          "company_name": "LAMB WESTON SALES INC",
          "address": "2800 POLAR WAY",
          "city": "RICHLAND",
          "state": "WA",
          "zipcode": "99354",
          "lat":46.3358455,
          "lng":-119.3073969,
          "phone": "5093712800",
          "email": "d1shipping@lineagelogistics.com",
          "appointment_type": "FCFS",
          "appointment_date": "2024-11-01T23:59:00",
          "appointment_start_time": "",
          "appointment_end_time": "",
          "stop_type": "Pickup",
          "stop_instructions": ""
        },
        {
          "stop_number": 2,
          "company_name": "DC TRACY",
          "address": "800 EAST PESCADERO AVENUE",
          "city": "TRACY",
          "state": "CA",
          "zipcode": "95304",
          "phone": "2092217575",
          "lat":37.7597581,
          "lng":-121.4141388,
          "email": "",
          "appointment_type": "APPT",
          "appointment_date": "2024-11-04T23:59:00",
          "appointment_start_time": "",
          "appointment_end_time": "",
          "stop_type": "Delivery",
          "stop_instructions": "FROZEN: SET REFRIGERATION UNIT BETWEEN -10F AND 0F"
        }
      ]
    });
  }

  const handleConfirmClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const [expandedStops, setExpandedStops] = useState({});

  const handleInputChange = (path, value) => {
    setData(prev => {
      const newData = { ...prev };
      const pathArray = path.split('.');
      let current = newData;

      for (let i = 0; i < pathArray.length - 1; i++) {
        current = current[pathArray[i]];
      }
      current[pathArray[pathArray.length - 1]] = value;

      return newData;
    });
  };

  const toggleStop = (index) => {
    setExpandedStops(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const equipmentTypes = ["reefer", "van", "flatbed", "dryvan", "stepdeck", "lowboy"];
  const stopTypes = ["Pickup", "Delivery"];

  return (
    <HomeLayout>
      <div className="max-w-4xl mx-auto p-6">
        <h1 className="text-2xl font-bold mb-6">Rate Confirmation Review</h1>

        <div className="space-y-6">
          {/* Header Information */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Order Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Order Number
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={data.order_number}
                  onChange={(e) => handleInputChange('order_number', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Customer Name
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={data.customer_name}
                  onChange={(e) => handleInputChange('customer_name', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Equipment Type
                </label>
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={data.equipment_type}
                  onChange={(e) => handleInputChange('equipment_type', e.target.value)}
                >
                  {equipmentTypes.map(type => (
                    <option key={type} value={type}>
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Weight (lbs)
                </label>
                <input
                  type="number"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={data.weight}
                  onChange={(e) => handleInputChange('weight', parseFloat(e.target.value))}
                />
              </div>
            </div>
          </div>

          {/* Rates Section */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Rates</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Linehaul Rate ($)
                </label>
                <input
                  type="number"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={data.linehaul_rate.toFixed(2)}
                  onChange={(e) => handleInputChange('linehaul_rate', parseFloat(e.target.value))}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Fuel Surcharge ($)
                </label>
                <input
                  type="number"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={data.fuel_surcharge}
                  onChange={(e) => handleInputChange('fuel_surcharge', parseFloat(e.target.value))}
                />
              </div>
            </div>
          </div>

          {/* Carrier Information */}
          <div className="bg-white rounded-lg shadow p-6">
  <h2 className="text-xl font-semibold mb-4">Carrier Information</h2>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Carrier Name
      </label>
      <input
        type="text"
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={data.carrier.carrier_name}
        onChange={(e) => handleInputChange('carrier.carrier_name', e.target.value)}
      />
    </div>
    <div className="relative">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Driver Name
      </label>
      <input
        type="text"
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={data.carrier.driver_name}
        onChange={(e) => {
          handleInputChange('carrier.driver_name', e.target.value);
          if (drivers[e.target.value]) {
            handleInputChange('carrier.driver_phone', drivers[e.target.value].phone);
            data.carrier.truck = drivers[e.target.value].truck
            data.carrier.trailer = drivers[e.target.value].trailer
          }
        }}
        list="driversList"
      />
      <datalist id="driversList">
        {Object.keys(drivers).map(driver => (
          <option key={driver} value={driver} />
        ))}
      </datalist>
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Driver Phone
      </label>
      <input
        type="text"
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={data.carrier.driver_phone}
        onChange={(e) => handleInputChange('carrier.driver_phone', e.target.value)}
      />
    </div>
  </div>
</div>

          {/* Stops Section */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Stops</h2>
            <div className="space-y-4">
              {data.stops.map((stop, index) => (
                <div key={index} className="border border-gray-200 rounded-lg">
                  <button
                    className="w-full px-4 py-3 text-left flex justify-between items-center hover:bg-gray-50"
                    onClick={() => toggleStop(index)}
                  >
                    <span className="font-medium">
                      Stop {stop.stop_number}: {stop.company_name}
                    </span>
                    <span className="transform transition-transform duration-200">
                      {expandedStops[index] ? '▼' : '▶'}
                    </span>
                  </button>
                  {expandedStops[index] && (
                    <div className="p-4 border-t border-gray-200">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Company Name
                          </label>
                          <input
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={stop.company_name}
                            onChange={(e) => handleInputChange(`stops.${index}.company_name`, e.target.value)}
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Address
                          </label>
                          <input
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={stop.address}
                            onChange={(e) => handleInputChange(`stops.${index}.address`, e.target.value)}
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            City
                          </label>
                          <input
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={stop.city}
                            onChange={(e) => handleInputChange(`stops.${index}.city`, e.target.value)}
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            State
                          </label>
                          <input
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={stop.state}
                            onChange={(e) => handleInputChange(`stops.${index}.state`, e.target.value)}
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Appointment Date
                          </label>
                          <input
                            type="datetime-local"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={stop.appointment_date.split('.')[0]}
                            onChange={(e) => handleInputChange(`stops.${index}.appointment_date`, e.target.value)}
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Stop Type
                          </label>
                          <select
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={stop.stop_type}
                            onChange={(e) => handleInputChange('stop_type', e.target.value)}
                          >
                            {stopTypes.map(type => (
                              <option key={type} value={type}>
                                {type.charAt(0).toUpperCase() + type.slice(1)}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='col-span-2'>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Instructions
                          </label>
                          <input
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={stop.stop_instructions || ''}
                            onChange={(e) => handleInputChange(`stops.${index}.stop_instructions`, e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end space-x-4">
            <button
              className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setShowPopup(true);
              }}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Confirm Rate
            </button>
          </div>
        </div>
      </div>
      {showPopup && (
        <Modal
          style={customStyles}
          isOpen={showPopup}
          overlayClassName="overlay-detention-message opacity-1"
        >
          <div className="p-6 rounded-lg">
            <h2 className="text-xl font-semibold">Confirm Rate</h2>
            <p className="text-gray-700 mb-4">Are you sure you want to confirm this rate?</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleClosePopup}
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setShowPopup(false);
                  dispatch(addManualLoad(createLoad(data)));
                  navigate('/');
                }}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}
    </HomeLayout>
  );
};

export default RateConfirmationPage;