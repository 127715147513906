import { useAppDispatch } from '../../redux/hooks';
import { addManualLoad } from '../../redux/reducer';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import HomeLayout from '../Layout/homeLayout';
import Modal from "react-modal";
import { Document, Page, pdfjs } from 'react-pdf';
import FormTextInput from './formTextInput'
import FormDropDownInput from './formDropDown'
import FormDropDownWithIdInput from './formDropDownWithId';
import DatePicker from "../Dropdown/datePicker";
import { getDateFromDatePicker } from "../../utils/dateutils";
pdfjs.GlobalWorkerOptions.workerSrc = '/pdfjs/pdf.worker.min.mjs';

const getDriverMappings = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_SERVER}/v1/mappings/BE931/driver_id`);

  if (!response.ok) {
    throw new Error('Failed to get driver data');
  }

  const data = await response.json();
  return data
}

const getCustomerMappings = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_SERVER}/v1/mappings/BE931/customer_id`);

  if (!response.ok) {
    throw new Error('Failed to get driver data');
  }

  const data = await response.json();
  console.log(data)
  return data
}

const getTruckMappings = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_SERVER}/v1/mappings/BE931/truck_id`);

  if (!response.ok) {
    throw new Error('Failed to get truck data');
  }

  const data = await response.json();
  return data
}

const getTrailerMappings = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_SERVER}/v1/mappings/BE931/trailer_id`);

  if (!response.ok) {
    throw new Error('Failed to get trailer data');
  }

  const data = await response.json();
  return data
}

// TODO: Need to incorporate this into a dropdown
// TODO: The rest of the mappings need to move to the database
const states = {
  'Alabama': 'AL', 'Alaska': 'AK', 'Arizona': 'AZ', 'Arkansas': 'AR', 'California': 'CA',
  'Colorado': 'CO', 'Connecticut': 'CT', 'Delaware': 'DE', 'District of Columbia': 'DC',
  'Florida': 'FL', 'Georgia': 'GA', 'Hawaii': 'HI', 'Idaho': 'ID', 'Illinois': 'IL',
  'Indiana': 'IN', 'Iowa': 'IA', 'Kansas': 'KS', 'Kentucky': 'KY', 'Louisiana': 'LA',
  'Maine': 'ME', 'Maryland': 'MD', 'Massachusetts': 'MA', 'Michigan': 'MI', 'Minnesota': 'MN',
  'Mississippi': 'MS', 'Missouri': 'MO', 'Montana': 'MT', 'Nebraska': 'NE', 'Nevada': 'NV',
  'New Hampshire': 'NH', 'New Jersey': 'NJ', 'New Mexico': 'NM', 'New York': 'NY',
  'North Carolina': 'NC', 'North Dakota': 'ND', 'Ohio': 'OH', 'Oklahoma': 'OK', 'Oregon': 'OR',
  'Pennsylvania': 'PA', 'Rhode Island': 'RI', 'South Carolina': 'SC', 'South Dakota': 'SD',
  'Tennessee': 'TN', 'Texas': 'TX', 'Utah': 'UT', 'Vermont': 'VT', 'Virginia': 'VA',
  'Washington': 'WA', 'West Virginia': 'WV', 'Wisconsin': 'WI', 'Wyoming': 'WY'
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#EDEDF6',
  }
};

const RateConfirmationPage = () => {
  const dispatch = useAppDispatch();
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const location = useLocation();
  const { pdfData, parsedData } = location.state || {};

  const [drivers, setDrivers] = useState({});
  const [customers, setCustomers] = useState({});
  const [trucks, setTrucks] = useState({});
  const [trailers, setTrailers] = useState({});

  useEffect(() => {
    getDriverMappings().then((mappings) => {
      setDrivers(mappings)
    })
    getCustomerMappings().then((mappings) => {
      setCustomers(mappings)
    })
    getTruckMappings().then((mappings) => {
      setTrucks(mappings)
    })
    getTrailerMappings().then((mappings) => {
      setTrailers(mappings)
    })
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  var [data, setData] = useState({
    "order_number": parsedData.order_number,
    "customer_name": parsedData.customer_name,
    "customer_id": "",
    "po_number": parsedData.po_number,
    "bol_number": parsedData.bol_number,
    "reference_number": parsedData.reference_number,
    "phone": "",
    "email": "",
    "linehaul_rate": parsedData.linehaul_rate,
    "fuel_surcharge": parsedData.fuel_surcharge,
    "weight": parsedData.weight,
    "length": parsedData.length,
    "equipment_type": parsedData.equipment_type,
    "temperature_control": parsedData.temperature_control,
    "temperature": parsedData.temperature,
    "carrier": {
      "carrier_name": parsedData.carrier.carrier_name,
      "driver_name": parsedData.carrier.driver_name,
      "driver_id": "",
      "driver_phone": parsedData.carrier.driver_phone,
      "truck": parsedData.carrier.truck,
      "truck_id": "",
      "trailer": parsedData.carrier.trailer,
      "trailer_id": "",
    },
    "truck_location": {
        "address": "",
        "lat": 0,
        "lng": 0,
    },
    "stops": parsedData.stops.map((stop, index) => ({
        "stop_number": stop.stop_number,
        "company_name": stop.company_name,
        "company_id": "",
        "address": stop.address,
        "city": stop.city,
        "state": stop.state,
        "zipcode": "",
        "lat": 0,
        "lng": 0,
        "phone": "",
        "email": "",
        "confirmation_number": stop.confirmation_number,
        "bol_number": stop.bol_number,
        "reference_number": stop.reference_number,
        "appointment_type": stop.appointment_type,
        "appointment_date": stop.appointment_date,
        "appointment_time": stop.appointment_start_time,
        "fcfs_start_date": stop.appointment_date,
        "fcfs_start_time": stop.appointment_start_time,
        "fcfs_end_date": stop.appointment_date,
        "fcfs_end_time": stop.appointment_start_time,
        "stop_type": stop.stop_type,
        "stop_instructions": stop.stop_instructions,
        "commodities": stop.commodities ? stop.commodities.map(x => x + ' ').join(' ') : ""
      }))
  })

  const handleConfirmClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const [expandedStops, setExpandedStops] = useState({});
  const [pdfWidth, setPdfWidth] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      setPdfWidth(containerRef.current.clientWidth);
    }
  }, []);

  const handleInputChange = (path, value) => {
    console.log(path)
    console.log(value)
    setData(prev => {
      const newData = { ...prev };
      const pathArray = path.split('.');
      let current = newData;

      for (let i = 0; i < pathArray.length - 1; i++) {
        current = current[pathArray[i]];
      }
      current[pathArray[pathArray.length - 1]] = value;

      return newData;
    });
  };

  const toggleStop = (index) => {
    setExpandedStops(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const equipmentTypes = {"reefer":"Reefer", "van":"Van"};
  const stopTypes = {"Pickup":"Pickup", "Delivery":"Delivery"};

  return (
    <HomeLayout>
      <div className="flex h-[calc(100vh-64px)]"> 
        <div className="w-1/3 p-4 bg-gray-100 overflow-auto">
        {/* <div ref={containerRef} className="w-1/3 p-4 bg-gray-100"> */}
      <Document
        file={`data:application/pdf;base64,${pdfData}`}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} 
          width={pdfWidth}
        />
      </Document>
          <div className="flex justify-center mt-2">
            <button 
              disabled={pageNumber <= 1}
              onClick={() => setPageNumber(pageNumber - 1)}
              className="px-2 py-1 mr-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
            >
              Previous
            </button>
            <span>Page {pageNumber} of {numPages}</span>
            <button 
              disabled={pageNumber >= numPages}
              onClick={() => setPageNumber(pageNumber + 1)}
              className="px-2 py-1 ml-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
            >
              Next
            </button>
          </div>
        </div>
        <div className="w-2/3 overflow-auto">
          <div className="max-w-4xl mx-auto p-6"></div>
      <div className="max-w-4xl mx-auto p-6">
        <h1 className="text-2xl font-bold mb-6">Rate Confirmation Review</h1>

        <div className="space-y-6">
          {/* Header Information */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Order Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormTextInput
                label="Order Number"
                value={data.order_number}
                onChange={(value) => handleInputChange('order_number', value)}
              />
              <FormDropDownWithIdInput
                label="Company Name"
                value={data.customer_name}
                id={data.customer_id}
                onChange={(name, id) => {
                  handleInputChange('customer_name', name);
                  handleInputChange('customer_id', id);
                }}
                options={customers}
              />
              <FormDropDownInput
                label="Equipment Type"
                value={data.equipment_type}
                onChange={(value) => handleInputChange('equipment_type', value)}
                options={equipmentTypes}
              />
              <FormTextInput
                label="Equipment Length (ft)"
                value={data.length}
                onChange={(value) => handleInputChange('equipment_length', value)}
              />
              <FormTextInput
                label="Weight (lbs)"
                value={data.weight}
                onChange={(value) => handleInputChange('weight', parseFloat(value))}
              />
              <FormTextInput
                label="PO #"
                value={data.po_number}
                onChange={(value) => handleInputChange('po_number', value)}
              />
              <FormTextInput
                label="Reference #"
                value={data.reference_number}
                onChange={(value) => handleInputChange('reference_number', value)}
              />
              <FormTextInput
                label="BOL #"
                value={data.bol_number}
                onChange={(value) => handleInputChange('bol_number', value)}
              />
              {data.equipment_type === 'reefer' && (
                <>
                  <FormTextInput
                    label="Required Temperature (°F)"
                    value={data.temperature}
                    onChange={(value) => handleInputChange('temperature', value)}
                  />
                  <FormDropDownInput
                    label="Reefer Setting"
                    value={data.temperature_control}
                    onChange={(value) => handleInputChange('temperature_control', value)}
                    options={{'continuous':"Continuous",'start_stop':"Start Stop"}}
                  />
                </>
              )}
            </div>
          </div>

          {/* Rates Section */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Rates</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormTextInput
                label="Linehaul Rate ($)"
                value={data.linehaul_rate ? data.linehaul_rate.toFixed(2) : 0}
                onChange={(value) => handleInputChange('order_number', parseFloat(value))}
              />
              <FormTextInput
                label="Fuel Surcharge ($)"
                value={data.fuel_surcharge ? data.fuel_surcharge.toFixed(2) : 0}
                onChange={(value) => handleInputChange('fuel_surcharge', parseFloat(value))}
              />
            </div>
          </div>

          {/* Carrier Information */}
          <div className="bg-white rounded-lg shadow p-6">
  <h2 className="text-xl font-semibold mb-4">Carrier Information</h2>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
    <FormTextInput
      label="Carrier Name"
      value={data.carrier.carrier_name}
      onChange={(value) => handleInputChange('carrier.carrier_name', value)}
    />
    <FormDropDownWithIdInput
      label="Driver Name"
      value={data.carrier.driver_name}
      id={data.carrier.driver_id}
      onChange={(name, id) => {
        handleInputChange('carrier.driver_name', name);
        handleInputChange('carrier.driver_id', id);
      }}
      options={drivers}
    />
    <FormDropDownWithIdInput
      label="Truck"
      value={data.carrier.truck}
      id={data.carrier.truck_id}
      onChange={(name, id) => {
        handleInputChange('carrier.truck', name);
        handleInputChange('carrier.truck_id', id);
      }}
      options={trucks}
    />
    <FormDropDownWithIdInput
      label="Trailer"
      value={data.carrier.trailer}
      id={data.carrier.trailer_id}
      onChange={(name, id) => {
        handleInputChange('carrier.trailer', name);
        handleInputChange('carrier.trailer_id', id);
      }}
      options={trailers}
    />
    <FormTextInput
      label="Driver Phone"
      value={data.carrier.driver_phone}
      onChange={(value) => handleInputChange('carrier.driver_phone', value)}
    />
  </div>
</div>

          {/* Stops Section */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Stops</h2>
            <div className="space-y-4">
              {data.stops.map((stop, index) => (
                <div key={index} className="border border-gray-200 rounded-lg">
                  <button
                    className="w-full px-4 py-3 text-left flex justify-between items-center hover:bg-gray-50"
                    onClick={() => toggleStop(index)}
                  >
                    <span className="font-medium">
                      Stop {stop.stop_number}: {stop.company_name}
                    </span>
                    <span className="transform transition-transform duration-200">
                      {expandedStops[index] ? '▼' : '▶'}
                    </span>
                  </button>
                    <div className={`p-4 border-t border-gray-200 ${expandedStops[index] ? '' : 'hidden'}`}>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <FormDropDownWithIdInput
                          label="Company Name"
                          value={stop.company_name}
                          id={stop.company_id}
                          //onChange={(value) => handleInputChange('customer_name', value)}
                          onChange={(name, id) => {
                            handleInputChange(`stops.${index}.company_name`, name);
                            handleInputChange(`stops.${index}.company_id`, id);
                          }}
                          options={customers}
                        />
                        {/* <FormDropDownInput
                          label="Company Name"
                          value={stop.company_name}
                          onChange={(value) => handleInputChange(`stops.${index}.company_name`, value)}
                          options={customers}
                        /> */}
                        <FormTextInput
                          label="Address"
                          value={stop.address}
                          onChange={(value) => handleInputChange(`stops.${index}.address`, value)}
                        />
                        <FormTextInput
                          label="City"
                          value={stop.city}
                          onChange={(value) => handleInputChange(`stops.${index}.city`, value)}
                        />
                        <FormTextInput
                          label="State"
                          value={stop.state}
                          onChange={(value) => handleInputChange(`stops.${index}.state`, value)}
                        />
                        <FormDropDownInput
                          label="Stop Type"
                          value={stop.stop_type}
                          onChange={(value) => handleInputChange(`stops.${index}.stop_type`, value)}
                          options={stopTypes}
                        />
                        <div></div>
                        <FormDropDownInput
                          label="Appointment Type"
                          value={stop.appointment_type}
                          onChange={(value) => handleInputChange(`stops.${index}.appointment_type`, value)}
                          options={{'APPT':"APPT",'FCFS':"FCFS"}}
                        />
                        <div></div>
                        {stop.appointment_type === 'FCFS' && (
                          <>
                          <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Begin Date
                          </label>
                            <DatePicker
                                onChange={(date) => {
                                  handleInputChange(`stops.${index}.fcfs_start_date`, date)
                                }}
                                placeholder="Enter as MM/DD/YYYYY"
                                initialSearchText={getDateFromDatePicker(stop.appointment_date ? new Date(stop.fcfs_start_date) : new Date())}
                                value={stop.fcfs_start_date ? new Date(stop.appointment_date).toString() : null}
                                singleDate={true}
                                className={{
                                    parent: "min-w-[15.2rem]"
                                }}
                                allowinput={true}
                                inputFormat="MM/DD/YYYY"
                            />
                          </div>
                          <FormTextInput
                            label="Begin Time"
                            value={stop.fcfs_start_time}
                            onChange={(value) => handleInputChange(`stops.${index}.fcfs_start_time`, value)}
                          />
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              End Date
                            </label>
                            <DatePicker
                                onChange={(date) => {
                                  handleInputChange(`stops.${index}.fcfs_end_date`, date)
                                }}
                                placeholder="Enter as MM/DD/YYYYY"
                                initialSearchText={getDateFromDatePicker(stop.fcfs_end_date ? new Date(stop.fcfs_end_date) : new Date())}
                                value={stop.appointment_date ? new Date(stop.fcfs_end_date).toString() : null}
                                singleDate={true}
                                className={{
                                    parent: "min-w-[15.2rem]"
                                }}
                                allowinput={true}
                                inputFormat="MM/DD/YYYY"
                            />
                          </div>
                          <FormTextInput
                            label="End Time"
                            value={stop.fcfs_end_time}
                            onChange={(value) => handleInputChange(`stops.${index}.fcfs_end_time`, value)}
                          />
                          </>
                        )}
                        {stop.appointment_type === 'APPT' && (
                        <>
                          <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Appointment Date
                          </label>
                            <DatePicker
                                onChange={(date) => {
                                  handleInputChange(`stops.${index}.appointment_date`, date)
                                }}
                                placeholder="Enter as MM/DD/YYYYY"
                                initialSearchText={getDateFromDatePicker(stop.appointment_date ? new Date(stop.appointment_date) : new Date())}
                                value={stop.appointment_date ? new Date(stop.appointment_date).toString() : null}
                                singleDate={true}
                                className={{
                                    parent: "min-w-[15.2rem]"
                                }}
                                allowinput={true}
                                inputFormat="MM/DD/YYYY"
                            />
                          </div>
                          <FormTextInput
                            label="Appointment Time"
                            value={stop.appointment_time}
                            onChange={(value) => handleInputChange(`stops.${index}.appointment_time`, value)}
                          />
                        <FormTextInput
                          label="Appointment Confirmation #"
                          value={stop.confirmation_number}
                          onChange={(value) => handleInputChange(`stops.${index}.confirmation_number`, value)}
                        />
                        <div/>
                        </>
                        )}
                        <FormTextInput
                            label="Reference #"
                            value={stop.reference_number}
                            onChange={(value) => handleInputChange(`stops.${index}.reference_number`, value)}
                          />
                        <FormTextInput
                          label="BOL #"
                          value={stop.bol_number}
                          onChange={(value) => handleInputChange(`stops.${index}.bol_number`, value)}
                        />
                        <div/>
                        <FormTextInput
                          label="Commodities"
                          divClassName='col-span-2'
                          value={stop.commodities}
                          onChange={(value) => handleInputChange(`stops.${index}.commodities`, value)}
                        />
                        <FormTextInput
                          label="Instructions"
                          divClassName='col-span-2'
                          value={stop.stop_instructions}
                          onChange={(value) => handleInputChange(`stops.${index}.stop_instructions`, value)}
                        />
                      </div>
                    </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setShowPopup(true);
              }}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Confirm Rate
            </button>
          </div>
        </div>
      </div>
      </div>
        </div>
      {showPopup && (
        <Modal
          style={customStyles}
          isOpen={showPopup}
          overlayClassName="overlay-detention-message opacity-1"
        >
          <div className="p-6 rounded-lg">
            <h2 className="text-xl font-semibold">Confirm Rate</h2>
            <p className="text-gray-700 mb-4">Are you sure you want to confirm this rate?</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleClosePopup}
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={async () => {
                  setShowPopup(false);
                  console.log(data)
                  //dispatch(addManualLoad(createLoad(data)));
                  navigate('/');
                  const response = await fetch(`${process.env.REACT_APP_API_SERVER}/v1/addexternalload/`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                  });
                }}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Confirm
              </button>
            </div>
          </div>
          
        </Modal>
      )}
    </HomeLayout>
  );
};

export default RateConfirmationPage;