import { combineReducers } from "@reduxjs/toolkit";
import { createAction } from "@reduxjs/toolkit";
import { dataSlice } from "../slice/data";
import { notesSlice } from "../slice/notes";
import { availableTrucksSlice } from "../slice/availableTrucks";
import { manualTasksSlice } from "../slice/manualTask";
import { driversSlice } from "../slice/drivers";
import { loadsSlice } from "../slice/loads";
import { trailersSlice } from "../slice/trailers";
import { trucksSlice } from "../slice/trucks";
import { customerNameSlice } from "../slice/customerName";
import { usersSlice } from "../slice/users";
import { orderNumberSlice } from "../slice/orderNumber";

export const resetStore = createAction("RESET_STORE");


// Combine Reducers
const appReducer = combineReducers({
  data: dataSlice.reducer,
  notes: notesSlice.reducer,
  availableTrucks: availableTrucksSlice.reducer,
  manualTasks: manualTasksSlice.reducer,
  drivers: driversSlice.reducer,
  loads: loadsSlice.reducer,
  trailers: trailersSlice.reducer,
  trucks: trucksSlice.reducer,
  customerName: customerNameSlice.reducer,
  users: usersSlice.reducer,
  orderNumber: orderNumberSlice.reducer,
  // Add other reducers here if you have more
});

const rootReducer = (state, action) => {
  if (resetStore.match(action)) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const { setClickIndex, setEtaTab, addManualLoad } = dataSlice.actions;

export default rootReducer;
