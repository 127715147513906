import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import HomeLayout from '../Layout/homeLayout';

const UploadRateCon = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [parsedData, setParsedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file?.type === 'application/pdf') {
      setSelectedFile(file);
    }
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file?.type === 'application/pdf') {
  //     setSelectedFile(file);
  //   }
  // };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Remove the data:application/pdf;base64, prefix
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async (event) => {
    try {
      setIsLoading(true);
      // setError(null);
      
      const file = event.target.files[0];
      
      // Validate file type
      if (file.type !== 'application/pdf') {
        throw new Error('Please upload a PDF file');
      }

      const base64String = await convertToBase64(file);

      const payload = {
        fileName: file.name,
        fileContent: base64String,
      };

      const response = await fetch(`${process.env.REACT_APP_API_SERVER}/v1/pdfparse/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Failed to upload file');
      }

      const data = await response.json();
      setParsedData(data.parsedData);
      setFileData(base64String);
      console.log(data.parsedData);
      
    } catch (err) {
      //setError(err.message);
      console.error('Upload error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleContinue = () => {
    if (selectedFile) {
      navigate(`/confirmRateCon?file=${selectedFile.name}`, { 
        state: { 
          pdfData: fileData, 
          parsedData: parsedData 
        } 
      })
    } else {
      navigate(`/confirmRateCon`, { 
        state: { 
          pdfData: fileData, 
          parsedData: parsedData 
        } 
      })
    }
    console.log('Continuing with file:', selectedFile);
  };

  return (
    <HomeLayout>
      {isLoading === true ? <span className="inline-block w-4 h-4 border-2 border-t-2 border-t-black border-white rounded-full animate-spin" /> : <div/>}
      <div className="max-w-xl mx-auto p-6">
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={`border-2 border-dashed rounded-lg p-8 mb-4 text-center transition-colors
          ${isDragging ? 'border-blue-500 bg-[#7A7CC0]' : 'border-gray-300 hover:border-gray-400'}
          ${selectedFile ? 'bg-green-50 border-green-500' : ''}`}
        >
          <div className="mb-4">
            <i className="fas fa-upload text-4xl text-gray-400"></i>
          </div>

          {selectedFile ? (
            <div className="text-green-600 font-medium">
              Selected: {selectedFile.name}
            </div>
          ) : (
            <>
              <p className="mb-2 text-sm text-gray-600">
                Drag and drop your PDF to extract the rate confirmation data, or
              </p>
              <label className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 cursor-pointer transition-colors">
                <span>Choose File</span>
                <input
                  id="pdfUpload"
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileChange}
                  disabled={false}
                  className="block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-md file:border-0
                    file:text-sm file:font-semibold
                    file:bg-blue-50 file:text-blue-700
                    hover:file:bg-blue-100"
                />
              </label>
            </>
          )}
        </div>

        <button
          onClick={handleContinue}
          className="w-full flex items-center justify-center gap-2 bg-[#7A7CC0] text-white py-2 px-4 rounded-md hover:bg-green-600 transition-colors"
        >
          {selectedFile ? 'Extract Rate Confirmation Data' : 'Manually enter rate info'}
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    </HomeLayout>
  );
};

export default UploadRateCon;