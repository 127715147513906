import { displayTime, displayShortDate } from "../../utils/dateutils";

type ExceptionHistoryProps = {
  history: {
    start_time_ms: string;
    total_time_ms: number;
    is_exception_high_range: boolean;
    ambient_temperature: string;
    reefer_temperature: number;
    location: string;
  }[];
  timezoneOffset: number;
  timezoneAbbreviation: string;
};
export default function ExceptionHistory(props: ExceptionHistoryProps) {
  console.log(props.timezoneOffset)

  return (
    <div className="flex flex-col w-full">
      <div className="text-xl text-[#1C244A] font-semibold mb-4">
        Exception History
      </div>
      <div className="rounded bg-gray-50 w-full px-4 py-4">
        <div className="grid grid-cols-5 gap-3 text-md border-b border-gray-500">
          <div className="pb-2">
            <span className="text-[#606479]">Time Stamp</span>
          </div>
          <div className="pb-2">
            <span className="text-[#606479]">Length</span>
          </div>
          <div className="pb-2">
            <span className="text-[#606479]">Temp Min/Max</span>
          </div>
          <div className="pb-2">
            <span className="text-[#606479]">Avg Reefer Temp</span>
          </div>
          <div className="pb-2">
            <span className="text-[#606479]">Location</span>
          </div>
        </div>
      </div>
      {(props?.history || []).map((history, index) => (
        <div
          className={`rounded bg-gray-50 w-full pb-1 px-4 ${
            (props?.history || []).length > 0
              ? props.history.length - 1 === index
                ? "pb-4"
                : ""
              : ""
          }`}
        >
          <div className="grid grid-cols-5 gap-3 text-md">
            <div>
              <span className="text-[#606479]">{displayShortDate(new Date(history.start_time_ms + props.timezoneOffset * 60 * 1000)) + " " + displayTime(new Date(history.start_time_ms + props.timezoneOffset * 60 * 1000))} {props.timezoneAbbreviation}</span>
            </div>
            <div>
              <span className="text-[#606479]">{history.total_time_ms ? (history.total_time_ms/(1000*60)).toString() + ' mins' : 'Ongoing'}</span>
            </div>
            <div>
              <span className="text-[#606479]">{history.is_exception_high_range ? "Temp Over Range" : "Temp Under Range"}</span>
            </div>
            <div>
              <span className="text-[#606479]">{history.reefer_temperature ? (history.reefer_temperature).toFixed(0).toString() + "° F" : ""}</span>
            </div>
            <div>
              <span className="text-[#606479]">{history.location}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
