import FlagException from "../FlagException/flagException";

export default function ExceptionDetailsHeader({ curr_data, notes }) {
    return (<div className="flex items-center justify-between">
        <div className="flex items-center my-4">
            <div className="mr-6 text-xl text-titleColor font-bold">
                Trip #{curr_data[0]?.trip_num}
            </div>
        </div>

        <div className="inline-flex gap-2">
            <FlagException flagged={curr_data[0]?.exception_status_tracker?.is_flagged} tripId={curr_data[0]?.load_num} />

            <div
                className="bg-indigo-500/100 rounded-sm w-max px-2 pt-[1px] pb-[1px] text-white text-xs font-semibold uppercase"
            >
                <div>
                    NOTES (
                    {
                        notes.filter((val) =>
                            val.trip_id.includes(curr_data[0]?.trip_num)
                        ).length
                    }
                    )
                </div>
            </div>
        </div>
    </div>);
}