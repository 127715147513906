type ReeferActualProps = {
  returnTemperature?: string;
  exceptionLength?: string;
  reeferMode?: string;
  source?: string;
  faultCode?: string;
  setPoint?: string;
  ambientTemp?: string;
  status?: string;
  inViolation: boolean;
};
export default function ReeferActual(props: ReeferActualProps) {
  return (
    <div className={`flex flex-col w-full `}>
      <div className="text-xl text-[#1C244A] font-semibold mb-4">
        Reefer Actual
      </div>
      <div className={`p-4 bg-gray-50 w-full rounded-lg ${props.inViolation ? "border-red-700 border-2" : ""}`}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 text-sm">
          {props.returnTemperature && <div>
            <span className="text-[#606479]">Return Temp:</span>
            <span className="text-[#1C244A]"> {props.returnTemperature + "° F"}</span>
          </div>}
          {props.exceptionLength && <div>
            <span className="text-[#606479]">Exception Length:</span>
            <span className="text-[#1C244A]"> {props.exceptionLength}</span>
          </div>}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 text-sm mt-4">
          {props.reeferMode && <div>
            <span className="text-[#606479]">Reefer Mode:</span>
            <span className="text-[#1C244A]"> {props.reeferMode}</span>
          </div>}
          {props.faultCode && <div>
            <span className="text-[#606479]">Fault Code:</span>
            <span className="text-[#1C244A]"> {props.faultCode}</span>
          </div>}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 text-sm mt-4">
          {props.setPoint && <div>
            <span className="text-[#606479]">Set Point:</span>
            <span className="text-[#1C244A]"> {props.setPoint}</span>
          </div>}
          {props.ambientTemp && <div>
            <span className="text-[#606479]">Ambient Temp:</span>
            <span className="text-[#1C244A]"> {props.ambientTemp + "° F"}</span>
          </div>}
        </div>

        {props.status && props.source &&<div className="grid grid-cols-1 sm:grid-cols-2 gap-3 text-sm mt-4">
          {props.status && <div>
            <span className="text-[#606479]">Status:</span>
            <span className="text-[#1C244A]"> {props.status}</span>
          </div>}
          {props.source && <div>
            <span className="text-[#606479]">Source:</span>{" "}
            <span
              className="text-blue-500 border-b border-blue-500 cursor-pointer"
              onClick={() => window.open(props.source, "_blank")}
            >
              {"Samsara Link"}
            </span>
          </div>}
        </div>}
      </div>
    </div>
  );
}
