import React, { useEffect, useRef } from 'react';
import H from '@here/maps-api-for-javascript';
const defaultLayer = require('../../../assets/images/maps/default.png');
const satelliteLayer = require('../../../assets/images/maps/satellite.png');

async function calculateRoute(platform, map, currentLocation, destinationLocation, stops, currentStop) {
  // Needed to know how to color the lines
  var routesDrawn = 0;

  function routeResponseHandler(response) {
    const sections = response.routes[0].sections
    const lineStrings = []

    sections.forEach((section) => {
      lineStrings.push(H.geo.LineString.fromFlexiblePolyline(section.polyline));
    })

    const lastStopIndex = stops.length - 1

    const multiLineString = new H.geo.MultiLineString(lineStrings);
    let lat_min = stops[0].location.lat < stops[lastStopIndex].location.lat ? stops[0].location.lat : stops[lastStopIndex].location.lat
    let lat_max = stops[0].location.lat > stops[lastStopIndex].location.lat ? stops[0].location.lat : stops[lastStopIndex].location.lat
    let lng_min = stops[0].location.long < stops[lastStopIndex].location.long ? stops[0].location.long : stops[lastStopIndex].location.long
    let lng_max = stops[0].location.long > stops[lastStopIndex].location.long ? stops[0].location.long : stops[lastStopIndex].location.long
    let padding_lat = (lat_max - lat_min) * .2
    let padding_lng = (lng_max - lng_min) * .2

    var boundingBox = new H.geo.Rect(lat_min - padding_lat,
      lng_min - padding_lng,
      lat_max + padding_lat,
      lng_max + padding_lng);

    // Create the polyline for the route
    var color = "#000000"
    var lineDash = [1, 0]
    if (routesDrawn == 0) {
      color = "#808080"
      lineDash = [2, 2]
    } else {
      color = "#000000"
    }
    const routePolyline = new H.map.Polyline(multiLineString, {
      style: {
        lineWidth: 4,
        strokeColor: color
      }
    })

    map.addObject(routePolyline);
    routesDrawn += 1;

    map.getViewModel().setLookAtData({
      bounds: boundingBox
    })
  }

  const router = platform.getRoutingService(null, 8);
  var firstMidpointStops = []
  var secondMidpointStops = []
  stops.forEach((stop, index, arr) => {
    if(index != 0 && index != arr.length - 1) {
      if(index < currentStop) {
        firstMidpointStops.push(stop.location.lat + "," + stop.location.long)
      } else {
        secondMidpointStops.push(stop.location.lat + "," + stop.location.long)
      }
    }
  });
  var routingParams = ""
  
  // Get the route from the first stop to the truck 
  // (including the stops inbetween that happened)
  const lastStopIndex = stops.length - 1
  if (firstMidpointStops.length) {
    routingParams = {
      'origin': `${stops[0].location.lat},${stops[0].location.long}`,
      'destination': `${currentLocation.lat},${currentLocation.lng}`,
      'transportMode': 'truck',
      'via': firstMidpointStops ? firstMidpointStops.join(',') : "",
      'return': 'polyline'
    }
  } else {
    routingParams = {
      'origin': `${stops[0].location.lat},${stops[0].location.long}`,
      'destination': `${currentLocation.lat},${currentLocation.lng}`,
      'transportMode': 'truck',
      'return': 'polyline'
    }
  }
  await router.calculateRoute(routingParams, routeResponseHandler, console.error)

  // Get the route from the the truck to the last stop
  // (including the stops still to occur)
  if (secondMidpointStops.length) {
    routingParams = {
      'origin': `${currentLocation.lat},${currentLocation.lng}`,
      'destination': `${stops[lastStopIndex].location.lat},${stops[lastStopIndex].location.long}`,
      'transportMode': 'truck',
      'via': secondMidpointStops ? secondMidpointStops.join(',') : "",
      'return': 'polyline'
    }
  } else {
    routingParams = {
      'origin': `${currentLocation.lat},${currentLocation.lng}`,
      'destination': `${stops[lastStopIndex].location.lat},${stops[lastStopIndex].location.long}`,
      'transportMode': 'truck',
      'return': 'polyline'
    }
  }
  await router.calculateRoute(routingParams, routeResponseHandler, console.error)
}

export default function CreateMap(props) {
  var stopDisplayCount = 1
  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);

  useEffect(() => {
    if (!map.current) {
      platform.current = new H.service.Platform({
        apikey: process.env.REACT_APP_HERE_KEY
      });

      const defaultLayers = platform.current.createDefaultLayers({
        pois: true
      });

      const newMap = new H.Map(
        mapRef.current,
        defaultLayers.vector.normal.map, {
        zoom: 10,
      }
      );

      const behavior = new H.mapevents.Behavior(
        new H.mapevents.MapEvents(newMap)
      );

      map.current = newMap;
      setBaseLayers(defaultLayers);

      const markerTruckHtml = `<div style="color: #000080;margin: -5px 0 0 -5px;"><i class="fas fa-map-pin fa-lg"></i></div>`;
      const domTruckIcon = new H.map.DomIcon(markerTruckHtml, { anchor: {
        x: 10,
        y: -15
      }});
      const domTruckMarker = new H.map.DomMarker(props.currentLocation, { icon: domTruckIcon });

      props.stops.forEach((stop, index, arr) => {
        var color = 'orange'
        if(index == 0) {
          color = 'green'
        } else if(index == arr.length - 1) {
          color = 'red'
        }
        map.current.addObject(
          new H.map.Marker({lat: stop.location.lat, lng: stop.location.long}, {
            icon: getMarkerIcon(color, stopDisplayCount)
          })
        )
        stopDisplayCount += 1
      });

      map.current.addObject(domTruckMarker)

      calculateRoute(platform.current, map.current, props.currentLocation, props.destinationLocation, props.stops, props.activeStopNumber);
    } else {
      // Update the map layer when mapLayer state changes
      const defaultLayers = platform.current.createDefaultLayers();
      setBaseLayers(defaultLayers);
    }

    function setBaseLayers(defaultLayers) {
      if (props.mapLayer === 'normal') {
        map.current.setBaseLayer(defaultLayers.vector.normal.map);
      } else if (props.mapLayer === 'satellite') {
        map.current.setBaseLayer(defaultLayers.raster.satellite.map);
      }
    }
  }, [props.mapLayer]);

  function getMarkerIcon(color, number) {
    const svgCircle = `<svg width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="marker">
                    <circle cx="7" cy="7" r="7" fill="${color}" stroke="${color}" stroke-width="1" />
                    <text x="7" y="8" text-anchor="middle" font-size="12" fill="white" dominant-baseline="middle">${number}</text>
                    </g></svg>`;
    return new H.map.Icon(svgCircle, {
      anchor: {
        x: 7,
        y: 7
      }
    })
  }

  const handleLayerChange = () => {
    if (props.mapLayer === 'normal') {
      props?.handleMapLayerChange();
    } else {
      props?.handleMapLayerChange();
    }
  };

  return (
    <>
      <div
        style={{ height: !props.expanded && "250px" }}
        className="w-full"
        ref={mapRef}
      >
        <div id="satellite" className={`absolute bottom-0 left-0 border p-1 text-black bg-white z-10 m-2 rounded`} onClick={handleLayerChange}>
          <img src={props.mapLayer === "satellite" ? defaultLayer : satelliteLayer} alt="logo" className="w-12 h-12" />
        </div>
      </div>
    </>
  );
}