import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import HomeLayout from '../Layout/homeLayout';

const UploadRateCon = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file?.type === 'application/pdf') {
      setSelectedFile(file);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file?.type === 'application/pdf') {
      setSelectedFile(file);
    }
  };

  const handleContinue = () => {
    if (selectedFile) {
      navigate(`/confirmRateCon?file=${selectedFile.name}`)
    } else {
      navigate(`/confirmRateCon`)
    }
    console.log('Continuing with file:', selectedFile);
  };

  return (
    <HomeLayout>
      <div className="max-w-xl mx-auto p-6">
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={`border-2 border-dashed rounded-lg p-8 mb-4 text-center transition-colors
          ${isDragging ? 'border-blue-500 bg-[#7A7CC0]' : 'border-gray-300 hover:border-gray-400'}
          ${selectedFile ? 'bg-green-50 border-green-500' : ''}`}
        >
          <div className="mb-4">
            <i className="fas fa-upload text-4xl text-gray-400"></i>
          </div>

          {selectedFile ? (
            <div className="text-green-600 font-medium">
              Selected: {selectedFile.name}
            </div>
          ) : (
            <>
              <p className="mb-2 text-sm text-gray-600">
                Drag and drop your PDF to extract the rate confirmation data, or
              </p>
              <label className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 cursor-pointer transition-colors">
                <span>Choose File</span>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  className="hidden"
                />
              </label>
            </>
          )}
        </div>

        <button
          onClick={handleContinue}
          className="w-full flex items-center justify-center gap-2 bg-[#7A7CC0] text-white py-2 px-4 rounded-md hover:bg-green-600 transition-colors"
        >
          {selectedFile ? 'Extract Rate Confirmation Data' : 'Manually enter rate info'}
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    </HomeLayout>
  );
};

export default UploadRateCon;