import { isExternalDriver, isNullOrUndefined, isTemperatureViolation } from "../../utils/triputils";
import CurrentLocationCard from "../CurrentLocation/currentlocation";
import CustomerCard from "../CustomerCard/customerCard";
import DriverCard from "../Driver/driver";
import ExceptonDetailsLayout from "../Layout/exceptonDetailsLayout";
import TripDetails from "../TripDetails/tripDetails";
import ExpandableMap from "../map/expandableMap";
import LoadRequirementsCard from "../TemperatureMonitoring/LoadRequirements";
import ReeferActual from "../TemperatureMonitoring/ReeferActual";
import TempHistoryGraph from "../TemperatureMonitoring/TempHistoryGraph";
import ExceptionHistory from "../TemperatureMonitoring/ExceptionHistory";
import ExceptionNotesSection from "../ExceptionNotesSection/exceptionnotessection";
import { useAppSelector } from "../../redux/hooks";
import ExceptionDetailsHeader from "../ExceptionDetailsHeader/ExceptionDetailsHeader";
import ExceptionDetailTitle from "../ExceptionDetailsHeader/ExceptionDetailTitle";
import { ExceptionType } from "../../types";


export function TempViolationExceptionDetails({ curr_data, completed }: {
    curr_data: any;
    completed?: boolean;
}) {
    if (!curr_data?.length) {
        return null;
    }
    const etaTab = useAppSelector((state) => state.data.etaTab);

    return (
        <>
            <ExceptionDetailTitle trip={curr_data} module={ExceptionType.TEMP_VIOLATION} />
            <ExceptonDetailsLayout
                leftCardSec={
                    <TripDetails
                        curr_data={curr_data}
                        active_stop_num={curr_data[0]?.active_stop_num} />
                }
                rightCardSec={
                    <div>
                        <ExceptionDetailsHeader curr_data={curr_data} completed={completed} taskConfig={{
                            createdFromId: curr_data[0]?.trip_num,
                            createdFromType: "tempViolation"
                        }} />

                        {curr_data?.map((delivery, index) => {
                            return !completed ? (
                                <div
                                    className="flex flex-row gap-4 mb-4 w-full"
                                    key={`del_${index}`}
                                >
                                    <div className="w-3/4 flex flex-col gap-4">
                                        <div className="flex w-full">
                                            <DriverCard
                                                driverName={delivery?.driver?.driver_name}
                                                contactPhone={delivery?.driver?.contact_phone}
                                                truckNum={delivery?.truck_num}
                                                trailerNum={delivery?.trailer_num}
                                            />
                                        </div>
                                        <div className="flex w-full">
                                            <CustomerCard
                                                customerName={delivery?.customer_name}
                                                loadNum={delivery?.load_num}
                                                tmsLinkInfo={delivery?.external_tms_summary}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex p-4 w-1/4 rounded bg-gray-50 text-sm justify-center">
                                        <CurrentLocationCard
                                            address={delivery?.current_location?.address1}
                                            isLate={curr_data[0]?.eta_details?.eta_calculations ? curr_data[0]?.eta_details?.eta_calculations[etaTab]?.is_late : false}
                                            eta={curr_data[0]?.eta_details?.eta_calculations ? curr_data[0]?.eta_details?.eta_calculations[etaTab]?.eta : null}
                                            lastUpdated={delivery?.current_location?.last_updated}
                                            delivery_timezone={delivery?.delivery_timezone}
                                            isExternalDriver={isExternalDriver(curr_data[0]?.driver)}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="flex flex-row gap-4 mb-4 w-full"
                                    key={`del_${index}`}
                                >
                                    <div className="w-full flex flex-col">
                                        <div className="flex w-full">
                                            <DriverCard
                                                driverName={delivery?.driver?.driver_name}
                                                contactPhone={delivery?.driver?.contact_phone}
                                                truckNum={delivery?.truck_num}
                                                trailerNum={delivery?.trailer_num}
                                                completed={completed}
                                            />
                                        </div>
                                        <div className="flex w-full">
                                            <CustomerCard
                                                customerName={delivery?.customer_name}
                                                loadNum={delivery?.load_num}
                                                tmsLinkInfo={delivery?.external_tms_summary}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                        {curr_data[0]?.temperature_monitoring_details && <div className={`${completed ? "w-full" : "w-3/4"} flex flex-col gap-4 mb-4`}>

                            <LoadRequirementsCard
                                allowableRange={curr_data[0]?.temperature_monitoring_details?.allowable_temperature_range}
                                reeferMode={curr_data[0]?.temperature_monitoring_details?.reefer_mode}
                                requiredTemperature={curr_data[0]?.temperature_monitoring_details?.required_temperature}
                            />

                            {
                                !isNullOrUndefined(curr_data[0]?.temperature_monitoring_details?.temperature_exception_status) &&
                                <>
                                    <ReeferActual
                                        inViolation={isTemperatureViolation(curr_data[0])}
                                        ambientTemp={curr_data[0]?.temperature_monitoring_details?.ambient_temperature}
                                        returnTemperature={curr_data[0]?.temperature_monitoring_details?.return_temperature}
                                        reeferMode="Not Available"
                                    />
                                </>
                            }
                        </div>}

                        {
                            <>
                                {curr_data[0]?.temperature_monitoring_details?.temp_exceptions && curr_data[0]?.temperature_monitoring_details?.temp_exceptions.length ?
                                    (<div className="flex flex-col gap-4 mb-4">
                                        <ExceptionHistory
                                            history={curr_data[0]?.temperature_monitoring_details?.temp_exceptions}
                                            timezoneOffset={curr_data[0]?.customer_timezone_offset}
                                            timezoneAbbreviation={curr_data[0]?.customer_timezone}
                                            />
                                    </div>
                                    ) : <></>}
                                {curr_data[0]?.temperature_monitoring_details?.temp_history ?
                                    (<div className="flex flex-col gap-4 mb-4 bg-white">
                                        <TempHistoryGraph
                                            tempHistoryList={curr_data[0]?.temperature_monitoring_details?.temp_history}
                                            requiredTemp={curr_data[0]?.temperature_monitoring_details?.required_temperature}
                                            minTemp={curr_data[0]?.temperature_monitoring_details?.min_required_temperature}
                                            maxTemp={curr_data[0]?.temperature_monitoring_details?.max_required_temperature}
                                            timezoneOffset={curr_data[0]?.customer_timezone_offset}
                                        />
                                    </div>
                                    ) : <></>}
                            </>
                        }

                        {curr_data[0]?.current_location?.lat && !completed ?
                            <ExpandableMap
                                curr_data={curr_data}
                            /> : null}

                        <ExceptionNotesSection originId={curr_data[0]?.trip_num} originType="TRIP" exceptionType="TEMP_MONITORING" />
                    </div>
                }
            ></ExceptonDetailsLayout>
        </>
    );
}