export default function UpdateFilter({ onClick }: { onClick: () => void}) {
    return (
        <button
            className={"bg-[#1A00E2] rounded-sm w-max px-2 p-0.5 text-white text-xs font-semibold uppercase"}
            onClick={onClick}
        >
            Update Results
        </button>
    )
}
